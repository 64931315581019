import React, { useContext, Fragment, useState, useEffect } from "react";
import { ThemeContext } from "styled-components";
import { Box, Heading, withWindowSize } from "@thecb/components";
import { ScreendoorFormContent } from "./ScreendoorForm.styled.js";
import { Helmet } from "react-helmet";
import { FONT_WEIGHT_REGULAR } from "../../../../constants/style_constants.js";
import ScriptTag from "react-script-tag";

const injectRendererScript = projectId => {
  const script = document.createElement("script");
  // All Const here would preferably be set by CMS params
  const invoiceDesc = "testing123";
  const invoiceSubDesc = "testing456";
  const payFieldID = "ff6sld67";
  const payRouting = "cityville_parking";
  script.innerHTML = `
      // Uncomment this line and set it to the CSS class that your website uses for buttons:
      FormRenderer.BUTTON_CLASS = 'btn themePrimaryButton';


      // Uncomment this line if you want respondents to be able to access a permalink to their draft:
      // FormRenderer.addPlugin('BookmarkDraft');
        function payDoor(){
        var pickedValues = _.pick(fr.getValue(), '${payFieldID}');
        var submissionId = fr.options.response.id;
        console.log(submissionId);
        let payPrice = pickedValues.${payFieldID}.dollars  + pickedValues.${payFieldID}.cents;
        var payData = {
          "account_id": "12345678",
          "line_items": [
            {
              "description": "${invoiceDesc}",
              "sub_description": "${invoiceSubDesc}",
              "amount": payPrice,
              "custom_attributes": [
                {
                  "key": "reference_id",
                  "value": submissionId
                }
              ]
            }
          ],
          "cancel_url": {
            "url": "https://thecitybase.com/",
            "label": "Cancel"
          },
          "return_url": {
            "url": "https://thecitybase.com/",
            "label": "Return"
          },
          "custom_attributes": [
            {
              "key": "customer_invoice_id",
              "value": "9182736450"
            }
          ]
        }
        fetch("https://invoice-service-product.dev.cityba.se/invoices/${payRouting}/permits", {
          method: "POST",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(payData)
        }).then(res => {
          window.location.replace(res.url);
        });
      }


        fr = new FormRenderer({"project_id":"${projectId}","afterSubmit": payDoor});




`;
  document.body.appendChild(script);
};
const ScreendoorBlock = ({ title, screendoorProjectId }) => {
  const themeContext = useContext(ThemeContext);
  const [jQueryLoaded, setJQueryLoaded] = useState(!!window.jQuery);
  const [formRendererLoaded, setFormRendererLoaded] = useState(
    !!window.FormRenderer
  );
  const { isMobile } = themeContext;

  useEffect(() => {
    if (jQueryLoaded && formRendererLoaded) {
      injectRendererScript(screendoorProjectId);
    }
  }, [jQueryLoaded, formRendererLoaded]);

  return (
    <Fragment>
      <Helmet>
        <link
          href="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
        />
      </Helmet>
      {!jQueryLoaded && (
        <ScriptTag
          type="text/javascript"
          nonce="3e1672f13999159a5f928ceaa6729b38"
          src="//code.jquery.com/jquery-2.2.3.min.js"
          onLoad={() => setJQueryLoaded(true)}
        />
      )}
      {jQueryLoaded && !formRendererLoaded && (
        <ScriptTag
          type="text/javascript"
          nonce="3e1672f13999159a5f928ceaa6729b38"
          src="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.js"
          onLoad={() => setFormRendererLoaded(true)}
        />
      )}

      <Box padding={isMobile ? "24px 0 0 0" : "40px 0 0 0"} background="white">
        <Heading variant="h4" weight={FONT_WEIGHT_REGULAR}>
          {title}
        </Heading>
        <Box padding="0">
          <ScreendoorFormContent data-formrenderer></ScreendoorFormContent>
        </Box>
      </Box>
    </Fragment>
  );
};

export default withWindowSize(ScreendoorBlock);
