import React, { Fragment, useContext, useState, useEffect } from "react";
import { ThemeContext } from "styled-components";
import {
  Module,
  Box,
  Text,
  ButtonWithAction,
  Center,
  PaymentButtonBar,
  util,
  WalletIconSmall,
  EmailForm,
  WalletName,
  RadioSection,
  Title,
  Detail,
  Spinner
} from "@thecb/components";
import Slider from "../../../../../../components/slider";
import SliderPanel from "../../../../../../components/slider-panel";
import LoginPanel from "../payment-wallet/LoginPanel";
import VerifyAccountPanel from "../payment-wallet/VerifyAccountPanel";
import ForgotPasswordPanel from "../payment-wallet/ForgotPasswordPanel";
import ConfirmForgotPasswordPanel from "../payment-wallet/ConfirmForgotPasswordPanel";
import { WHITE } from "../../../../../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../../../constants/style_constants";
import { fallbackValues } from "../payment-wallet/PaymentWallet.theme";
import { EMAIL_TITLE } from "../../../../../../constants/forms";

const ContactScreenHeading = ({ stepCounterMobile, isMobile }) =>
  isMobile ? (
    stepCounterMobile
  ) : (
    <Title
      extraStyles="margin: 1rem 0 0.5rem 0; line-height: 2.25rem;"
      variant="large"
      weight={FONT_WEIGHT_SEMIBOLD}
      as="h2"
      id="payment-contact-heading"
      dataQa="payment-contact-heading"
    >
      Contact
    </Title>
  );

const LoggedOutContent = ({
  isMobile,
  stepCounterMobile,
  openWalletSlider,
  clientName,
  emailForm,
  emailFormActions,
  errors
}) => {
  return (
    <Box
      padding="0"
      // extraStyles taken from PaymentDetails spinner styles
      extraStyles={`min-height: 238px; width: 100%;`}
    >
      <ContactScreenHeading
        stepCounterMobile={stepCounterMobile}
        isMobile={isMobile}
      />
      <ButtonWithAction
        variant={isMobile ? "smallPrimary" : "primary"}
        action={openWalletSlider}
        contentOverride={true}
        borderRadius="4px"
        dataQa="log-in-to-pay-btn"
        extraStyles={`display: flex; 
            justify-content: center;
            align-items: center; 
            padding: 1rem 1.5rem;
            margin: 0 0 ${isMobile ? "1rem" : "1.5rem"} 0;
            width: 100%;`}
      >
        <Center as="span" intrinsic>
          <Box
            as="span"
            padding="0"
            borderRadius="4px"
            extraStyles="display: flex; justify-content: center; align-items: center;"
          >
            <WalletIconSmall colorOverride={WHITE} />
            <Text color={WHITE} extraStyles={"margin-left: 0.5rem;"}>
              Log in to pay with {clientName} Wallet
            </Text>
          </Box>
        </Center>
      </ButtonWithAction>
      <Box padding="0">
        <Detail
          extraStyles="margin: 0 0 0.5rem 0"
          variant="large"
          weight={FONT_WEIGHT_SEMIBOLD}
          id="receipt-email-contact-heading"
          dataQa="receipt-email-contact-heading"
        >
          Receipt Email
        </Detail>
        <Module
          allowContentOverflow={true}
          spacingBottom="0"
          margin="0"
          spacing="0"
        >
          <Box padding="0" background="transparent">
            <EmailForm
              {...emailForm}
              {...emailFormActions}
              showErrors={errors}
              isMobile={isMobile}
              isRequired={true}
            />
          </Box>
        </Module>
      </Box>
    </Box>
  );
};

const LoggedInContent = ({
  action,
  customerFullName,
  showEditEmail,
  savedEmails,
  emailForm,
  emailFormActions,
  errors,
  toggleEmail,
  selectedSavedEmail,
  isMobile,
  toggleShowEditEmail,
  contactEmail,
  selectNewEmail,
  stepCounterMobile
}) => {
  return (
    <Box
      padding="0"
      // extraStyles taken from PaymentDetails spinner styles
      extraStyles={`min-height: 238px; width: 100%;`}
    >
      <ContactScreenHeading
        stepCounterMobile={stepCounterMobile}
        isMobile={isMobile}
      />
      <WalletName
        actionText="Check out as a guest"
        text="Not you?"
        action={action}
        mainText={customerFullName}
        dataQa="check-out-as-guest-button"
      />
      <Detail
        extraStyles="margin: 0 0 0.5rem 0"
        variant="large"
        weight={FONT_WEIGHT_SEMIBOLD}
        id="receipt-email-contact-heading"
        dataQa="receipt-email-contact-heading"
      >
        Receipt Email
      </Detail>

      {!savedEmails?.length > 0 ? (
        <Module allowContentOverflow={true} spacing="0" margin="0" padding="0">
          <EmailForm
            {...emailForm}
            {...emailFormActions}
            showErrors={errors}
            isRequired={true}
          />
        </Module>
      ) : showEditEmail && savedEmails?.length > 0 ? (
        <Module allowContentOverflow={true} spacing="0" margin="0" padding="0">
          <RadioSection
            sections={savedEmails.concat({
              id: EMAIL_TITLE,
              title: EMAIL_TITLE,
              dataQa: "new-email-form",
              content: (
                <EmailForm
                  variant="checkout"
                  {...emailForm}
                  {...emailFormActions}
                  showErrors={errors}
                  isRequired={true}
                />
              )
            })}
            toggleOpenSection={id => toggleEmail(id)}
            openSection={selectedSavedEmail.contactId || EMAIL_TITLE}
            staggeredAnimation
            isMobile={isMobile}
            borderOverride="0"
          />
        </Module>
      ) : (
        <Box padding="0">
          <WalletName
            actionText="Edit"
            action={() => {
              selectNewEmail();
              toggleShowEditEmail(true);
            }}
            mainText={contactEmail || ""}
            dataQa="edit-email-address"
            actionTextPositionMobile="inside"
          />
        </Box>
      )}
    </Box>
  );
};

const PaymentContact = ({
  cancelURL,
  cartEnabled,
  changePanel,
  closeWalletSlider,
  emailForm,
  emailFormActions,
  goToNextPage,
  guestCheckout,
  handleFocusErrors,
  isInvoice,
  isLoggedIn,
  isMobile,
  navigate,
  openWalletSlider,
  payment,
  profileResources,
  resendVerification,
  savedContactEmails,
  selectNewEmail,
  selectSavedEmail,
  stepCounterMobile,
  submitForgotPasswordForm,
  submitLoginForm,
  wallet,
  walletForgotPasswordAlertBar,
  walletForgotPasswordAlertBarActions,
  walletForgotPasswordForm,
  walletForgotPasswordFormActions,
  walletLoginAlertBar,
  walletLoginAlertBarActions,
  walletLoginForm,
  walletLoginFormActions,
  walletVerifyAlertBar,
  walletVerifyAlertBarActions,
  useCustomerInformationEmail,
  walletScreenV2Enabled,
  currentTabIndex,
  subPageActions
}) => {
  const {
    contactEmail,
    newEmailSelected,
    selectedSavedEmail,
    isLoadingProfileResources
  } = payment;
  const [errors, handleErrors] = useState(false);
  const [showEditEmail, toggleShowEditEmail] = useState(false);
  const { sliderOpen, panels, submitting, walletEmail } = wallet;
  const themeContext = useContext(ThemeContext);
  const themeValues = util.theme.createThemeValues(
    themeContext,
    fallbackValues,
    "PaymentWallet"
  );
  const { metadata } = themeContext;
  const clientName = metadata?.data?.clientDisplayName ?? "";
  const customerFullName = profileResources?.value
    ? `${profileResources.value?.firstName} ${profileResources.value?.lastName}`
    : "";
  const savedEmails =
    savedContactEmails && Object.keys(savedContactEmails)?.length > 0
      ? Object.values(savedContactEmails).map((item, index) => ({
          id: item.id,
          value: item.value,
          title: item.value,
          dataQa: `saved-email-${item.value}`,
          key: `inner-radio-section-${index}`
        }))
      : [];
  const toggleEmail = id =>
    savedContactEmails && id in savedContactEmails
      ? selectSavedEmail(id)
      : selectNewEmail();
  const handleSubmit = newEmailSelected
    ? util.general.generateClickHandler(
        emailForm,
        () => {
          handleErrors(true);
          handleFocusErrors(true);
        },
        () => goToNextPage()
      )
    : () => goToNextPage();

  const backButtonAction =
    isInvoice && cancelURL?.url
      ? () => (window.location = cancelURL?.url)
      : cartEnabled
      ? () => navigate("/cart")
      : () => navigate(-1);

  useEffect(() => {
    // If this is the first screen we hit when entering checkout, we can enter
    // a receipt email here, so we don't need to enable the Receipt email form
    // on the Customer Information screen
    if (currentTabIndex === 0 && walletScreenV2Enabled) {
      useCustomerInformationEmail(false);
    }
  }, [currentTabIndex, walletScreenV2Enabled]);

  useEffect(() => {
    // If the form has been edited and there are no errors, or if a saved email
    // is already selected, then we know we have a valid email address and
    // should select it by default on initial render.
    const isReceiptEmailPresent =
      (emailForm?.fields.email.dirty && !emailForm?.fields.email.hasErrors) ||
      !!selectedSavedEmail?.contactId;
    const hasSavedReceiptEmail = savedEmails && savedEmails?.length > 0;
    const firstEmailId = savedEmails && savedEmails?.[0]?.id;

    if (
      (!!selectedSavedEmail?.contactId &&
        selectedSavedEmail.contactId === firstEmailId) ||
      (!isReceiptEmailPresent && hasSavedReceiptEmail)
    ) {
      selectSavedEmail(firstEmailId);
    } else {
      selectNewEmail();
    }
  }, []);

  if (isLoadingProfileResources) {
    return (
      <Box
        padding="0"
        // extraStyles taken from PaymentDetails spinner styles
        extraStyles={`display: flex;
                  height: 238px;
                  width: 100%;
                  justify-content: center;
                  align-items: center;`}
      >
        <Spinner size="100" centerSpinner />
      </Box>
    );
  }

  return (
    <Fragment>
      <Box padding="0 24px 0 0">
        {isLoggedIn ? (
          <LoggedInContent
            action={() => {
              selectNewEmail();
              subPageActions?.clearPaymentContactInformation();
              guestCheckout();
            }}
            customerFullName={customerFullName}
            showEditEmail={showEditEmail}
            savedEmails={savedEmails}
            emailForm={emailForm}
            emailFormActions={emailFormActions}
            errors={errors}
            toggleEmail={id => toggleEmail(id)}
            selectedSavedEmail={selectedSavedEmail}
            isMobile={isMobile}
            toggleShowEditEmail={toggleShowEditEmail}
            contactEmail={contactEmail}
            stepCounterMobile={stepCounterMobile}
            selectSavedEmail={selectSavedEmail}
            selectNewEmail={selectNewEmail}
            newEmailSelected={newEmailSelected}
          />
        ) : (
          <LoggedOutContent
            isMobile={isMobile}
            stepCounterMobile={stepCounterMobile}
            openWalletSlider={openWalletSlider}
            clientName={clientName}
            emailForm={emailForm}
            emailFormActions={emailFormActions}
            errors={errors}
          />
        )}
        <PaymentButtonBar
          nextButtonTestId="Next"
          backButtonTestId="Back"
          backButtonAction={backButtonAction}
          cancelURL={cancelURL?.url}
          cancelText={cancelURL?.label}
          backButtonVariant={isMobile ? "smallSecondary" : "secondary"}
          hideBackButton={isInvoice && !cancelURL}
          forwardButtonAction={handleSubmit}
          forwardButtonText={"Next: Payment methods"}
          forwardButtonAriaRole={
            // If the form was edited, the element works like a button to submit a form
            // If the form is untouched, then the role should be link because it will just
            // take the user to the next screen
            emailForm.fields.email.dirty ? "button" : "link"
          }
          isMobile={isMobile}
          isForwardButtonDisabled={
            // If the user decides to update their receipt email for a given payment,
            // the email must be valid
            (newEmailSelected && emailForm.fields.email.hasErrors) ||
            // If logged out and the email form has an invalid value: The only way
            // to provide an email address when you're logged out is via this form
            (!isLoggedIn && emailForm.fields.email.hasErrors)
          }
        />
      </Box>

      <Slider isOpen={sliderOpen}>
        <SliderPanel status={panels.login.status} key="login-panel">
          <LoginPanel
            walletForm={walletLoginForm}
            walletFormActions={walletLoginFormActions}
            submitLoginForm={submitLoginForm}
            submitting={submitting}
            closeSlider={closeWalletSlider}
            panelIsOpen={sliderOpen && panels.login.status === "onScreen"}
            walletLoginAlertBar={walletLoginAlertBar}
            walletLoginAlertBarActions={walletLoginAlertBarActions}
            changePanel={changePanel}
            handleFocusErrors={handleFocusErrors}
            themeValues={themeValues}
          />
        </SliderPanel>
        <SliderPanel
          status={panels.verifyAccount.status}
          key="verify-account-panel"
        >
          <VerifyAccountPanel
            closeSlider={closeWalletSlider}
            panelIsOpen={
              sliderOpen && panels.verifyAccount.status === "onScreen"
            }
            changePanel={changePanel}
            email={walletEmail}
            resendVerification={resendVerification}
            walletVerifyAlertBar={walletVerifyAlertBar}
            walletVerifyAlertBarActions={walletVerifyAlertBarActions}
          />
        </SliderPanel>
        <SliderPanel
          status={panels.forgotPassword.status}
          key="forgot-password-panel"
        >
          <ForgotPasswordPanel
            closeSlider={closeWalletSlider}
            panelIsOpen={
              sliderOpen && panels.forgotPassword.status === "onScreen"
            }
            changePanel={changePanel}
            submitForgotPasswordForm={submitForgotPasswordForm}
            walletForgotPasswordForm={walletForgotPasswordForm}
            walletForgotPasswordFormActions={walletForgotPasswordFormActions}
            submitting={submitting}
            walletForgotPasswordAlertBar={walletForgotPasswordAlertBar}
            walletForgotPasswordAlertBarActions={
              walletForgotPasswordAlertBarActions
            }
            handleFocusErrors={handleFocusErrors}
            themeValues={themeValues}
          />
        </SliderPanel>
        <SliderPanel
          status={panels.confirmForgotPassword.status}
          key="confirm-forgot-password-panel"
        >
          <ConfirmForgotPasswordPanel
            closeSlider={closeWalletSlider}
            panelIsOpen={
              sliderOpen && panels.confirmForgotPassword.status === "onScreen"
            }
            changePanel={changePanel}
            themeValues={themeValues}
          />
        </SliderPanel>
      </Slider>
    </Fragment>
  );
};

export default React.memo(PaymentContact);
