import * as R from "ramda";
import S from "../../../../util/Sanctuary";
import { push } from "connected-react-router";
import { assign, set } from "partial.lenses";
import { v4 as uuidv4 } from "uuid";
import { init } from "../../../../util/Nested";
import * as Nested from "../../../../util/Nested";
import {
  LOGOUT,
  CLEAR_STATE,
  CLEAR_PAYMENT_CONTACT_INFORMATION,
  GUEST_CHECKOUT
} from "../../../../state/reducer";
import {
  state as profileState,
  selectors as profileSelectors
} from "../../../profile/pages/user-profile";

import {
  getBillingAddress,
  getSubtotal,
  getFeesTotal,
  getCalculatedFees,
  getTotal,
  getFullAmountTotal,
  getAllowFullPayment,
  getTermsAndConditions,
  getPayerName,
  getSavedCard,
  getSavedBank,
  getPhoneNumber,
  getEmailAddress,
  getLineItems,
  getProcessedLineItems,
  getPartialAmountFormProps,
  getPartialAmountFormPropsFromPayment
} from "./Payment.selectors";
import {
  PaymentFormCard,
  PaymentFormACH,
  AddressForm,
  EmailForm,
  PhoneForm,
  LoginForm,
  ForgotPasswordForm,
  createPartialAmountFormState
} from "@thecb/components";

import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";
import * as CaptchaForm from "../../../../components/captcha-form/CaptchaForm.state";

import { bindActionCreators } from "redux";
import { PROFILE_KEY } from "../../../../util/state";
import {
  LOGIN_FORM_ACTION as WALLET_LOGIN_FORM_ACTION,
  ALERT_BAR_ACTION as WALLET_LOGIN_ALERT_BAR_ACTION,
  submitLoginForm
} from "../../../profile/pages/login/Login.state";
import {
  ALERT_BAR_ACTION as WALLET_VERIFY_ALERT_BAR_ACTION,
  resendVerification
} from "../../../profile/pages/account-verification/AccountVerification.state";
import {
  guestCheckout,
  fetchSubClientConfiguration,
  clearPaymentContactInformation
} from "../../../../state/reducer";
import {
  submitForgotPasswordForm,
  FORGOT_PASSWORD_FORM_ACTION as WALLET_FORGOT_PASSWORD_FORM_ACTION,
  ALERT_BAR_ACTION as WALLET_FORGOT_PASSWORD_ALERT_BAR_ACTION
} from "../../../profile/pages/forgot-password/ForgotPassword.state";
import { openCartSlider } from "../cart/ShoppingCart.state";

export const FULL_AMOUNT = "payment/FULL_AMOUNT";
export const PARTIAL_AMOUNT = "payment/PARTIAL_AMOUNT";

// PAYMENT TYPES
export const NEW_ACH = "new_ach";
export const NEW_CC = "new_credit_card";
export const SAVED_CC = "saved_credit_card";
export const SAVED_ACH = "saved_ach";

// PAYMENT INITIATORS
export const PAYMENT_INITIATOR_PROFILE = "PROFILE";
export const PAYMENT_INITIATOR_WALLET = "WALLET";
export const PAYMENT_INITIATOR_WEB_CHECKOUT = "WEB_CHECKOUT";

// VISIT ID ERROR CODES
export const VISIT_ID_CONFIG_ERROR = "client_not_configured";

const { fetchResources, POPULATE_PAYMENT_FROM_PROFILE } = profileState;
const {
  getProfileResources,
  getSavedBankAccounts,
  getSavedCreditCards,
  getSavedAddresses,
  getSavedEmails,
  getSavedPhoneNumbers
} = profileSelectors;

const newPaymentState = () => ({
  invoiceId: null,
  serviceName: null,
  initialized: true,
  routingKey: "",
  lineItems: [],
  walletEnabled: false,
  amountEnabled: true,
  methodEnabled: true,
  customerInformationEnabled: true,
  confirmationEnabled: true,
  selectedSavedCreditCardId: null,
  selectedSavedACHId: null,
  selectedPaymentMethodType: null,
  newAddressSelected: true,
  selectedSavedAddress: {
    addressId: null
  },
  newEmailSelected: true,
  selectedSavedEmail: {
    contactId: null
  },
  newPhoneSelected: true,
  selectedSavedPhone: {
    contactId: null
  },
  termsAndConditions: "",
  termsAndConditionsAgreedTo: false,
  paymentVerificationNumber: null,
  paymentServiceContact: null,
  paymentSubmitting: false,
  paymentRedirecting: false,
  duplicate: false,
  allowedPaymentMethods: [],
  paymentDate: (d => ({
    day: d.getDate(),
    month: d.getMonth() + 1,
    year: d.getFullYear()
  }))(new Date()),
  complete: false,
  isGuestCheckout: false
});

const initialState = {
  payment: {
    isGuestCheckout: false
  },
  invoice: S.RemoteData.NotAsked,
  localStorage: {},
  expiredURL: { url: "", label: "" },
  isExpiredSession: false,
  wallet: {
    submitting: false,
    sliderOpen: false,
    panels: {
      login: {
        status: "onScreen"
      },
      verifyAccount: {
        status: "next"
      },
      forgotPassword: {
        status: "next"
      },
      confirmForgotPassword: {
        status: "next"
      }
    },
    savedWalletData: {
      cardPayment: false,
      bankPayment: false,
      address: false,
      phone: false,
      email: false
    }
  },
  usesCustomerInformationEmail: false
};

export const FETCH_INVOICE = "payment/FETCH_INVOICE";
export const fetchInvoice = ({ invoiceId }) => ({
  type: FETCH_INVOICE,
  payload: { invoiceId }
});

export const FETCH_INVOICE_SUCCESS = "payment/FETCH_INVOICE_SUCCESS";
export const fetchInvoiceSuccess = () => ({
  type: FETCH_INVOICE_SUCCESS
});

export const FETCH_INVOICE_FAILURE = "payment/FETCH_INVOICE_FAILURE";
export const fetchInvoiceFailure = error => ({
  type: FETCH_INVOICE_FAILURE,
  error: true,
  payload: error
});

export const CREATE_PAYMENT = "payment/CREATE_PAYMENT";
export const createPayment = ({
  invoiceId,
  serviceName,
  lineItems,
  customAttributes,
  accountId,
  fees,
  routingKey,
  subClientSlug,
  terms,
  contact,
  paymentMaximumInCents,
  paymentMinimumInCents,
  allowedPaymentMethods,
  allowBankAccountType,
  partialPaymentAllowed,
  partialPaymentMinimumInCents,
  blockPartialPaymentOverpay,
  walletEnabled,
  returnURL,
  cancelURL,
  isProfilePayment,
  isExpiredSession,
  visitId,
  accountLookupConfigKey,
  configForPaymentTypes,
  cartId,
  status
}) => ({
  type: CREATE_PAYMENT,
  payload: {
    invoiceId,
    serviceName,
    lineItems,
    fees,
    routingKey,
    subClientSlug,
    terms,
    contact,
    customAttributes,
    accountId,
    paymentMaximumInCents,
    paymentMinimumInCents,
    allowedPaymentMethods,
    allowBankAccountType,
    partialPaymentAllowed,
    partialPaymentMinimumInCents,
    blockPartialPaymentOverpay,
    walletEnabled,
    returnURL,
    cancelURL,
    isProfilePayment,
    isExpiredSession,
    visitId,
    accountLookupConfigKey,
    configForPaymentTypes,
    cartId,
    status
  }
});

export const GET_VISIT_ID = "payment/GET_VISIT_ID";
const getVisitId = () => ({
  type: GET_VISIT_ID
});

export const VISIT_ID_SUCCESS = "payment/VISIT_ID_SUCCESS";
export const visitIdSuccess = visitId => ({
  type: VISIT_ID_SUCCESS,
  payload: {
    visitId
  }
});

export const VISIT_ID_FAILURE = "payment/VISIT_ID_FAILURE";
export const visitIdFailure = error => ({
  type: VISIT_ID_FAILURE,
  payload: {
    error,
    errorCode: error?.response?.errors?.[0]?.code ?? "unknown_error"
  }
});

const SELECT_PAYMENT_AMOUNT = "payment/SELECT_PAYMENT_AMOUNT";
const selectPaymentAmount = paymentAmount => ({
  type: SELECT_PAYMENT_AMOUNT,
  payload: { paymentAmount }
});

const SELECT_NEW_PAYMENT_METHOD = "payment/SELECT_NEW_PAYMENT_METHOD";
const selectNewPaymentMethod = methodType => ({
  type: SELECT_NEW_PAYMENT_METHOD,
  payload: { methodType }
});

const selectNewACHPaymentMethod = () => selectNewPaymentMethod(NEW_ACH);
const selectNewCreditCardPaymentMethod = () => selectNewPaymentMethod(NEW_CC);

const SELECT_SAVED_CARD_METHOD = "payment/SELECT_SAVED_CARD_METHOD";
const selectSavedCardMethod = instrumentId => ({
  type: SELECT_SAVED_CARD_METHOD,
  payload: { instrumentId }
});

const SELECT_SAVED_ACH_METHOD = "payment/SELECT_SAVED_ACH_METHOD";
const selectSavedAchMethod = instrumentId => ({
  type: SELECT_SAVED_ACH_METHOD,
  payload: { instrumentId }
});

const selectPartialAmount = () => selectPaymentAmount(PARTIAL_AMOUNT);
const selectFullAmount = () => selectPaymentAmount(FULL_AMOUNT);

const SELECT_SAVED_ADDRESS = "payment/SELECT_SAVED_ADDRESS";
const selectSavedAddress = addressId => ({
  type: SELECT_SAVED_ADDRESS,
  payload: { addressId }
});

const SELECT_NEW_ADDRESS = "payment/SELECT_NEW_ADDRESS";
const selectNewAddress = () => ({
  type: SELECT_NEW_ADDRESS
});

const SELECT_SAVED_EMAIL = "payment/SELECT_SAVED_EMAIL";
const selectSavedEmail = contactId => ({
  type: SELECT_SAVED_EMAIL,
  payload: { contactId }
});

const SELECT_NEW_EMAIL = "payment/SELECT_NEW_EMAIL";
const selectNewEmail = () => ({
  type: SELECT_NEW_EMAIL
});

const SELECT_SAVED_PHONE = "payment/SELECT_SAVED_PHONE";
const selectSavedPhone = contactId => ({
  type: SELECT_SAVED_PHONE,
  payload: { contactId }
});

const SELECT_NEW_PHONE = "payment/SELECT_NEW_PHONE";
const selectNewPhone = () => ({
  type: SELECT_NEW_PHONE
});

const TOGGLE_TERMS_AND_CONDITIONS = "payment/TOGGLE_TERMS_AND_CONDITIONS";
const toggleTermsAndConditions = () => ({
  type: TOGGLE_TERMS_AND_CONDITIONS
});

export const SUBMIT_PAYMENT = "payment/SUBMIT_PAYMENT";
const submitPayment = () => ({
  type: SUBMIT_PAYMENT
});

const SUBMIT_PAYMENT_SUCCESS = "payment/SUBMIT_PAYMENT_SUCCESS";
export const submitPaymentSuccess = (
  paymentVerificationNumber,
  authCode,
  serviceFeePaymentAuthCode,
  paymentRedirecting
) => ({
  type: SUBMIT_PAYMENT_SUCCESS,
  payload: {
    paymentVerificationNumber,
    authCode,
    serviceFeePaymentAuthCode,
    paymentRedirecting
  }
});

const SUBMIT_PAYMENT_DUPLICATE = "payment/SUBMIT_PAYMENT_DUPLICATE";
export const submitPaymentDuplicate = () => ({
  type: SUBMIT_PAYMENT_DUPLICATE
});

const SUBMIT_PAYMENT_FAILURE = "payment/SUBMIT_PAYMENT_FAILURE";
export const submitPaymentFailure = () => ({
  type: SUBMIT_PAYMENT_FAILURE,
  error: true
});

export const PARTIAL_AMOUNT_FORM_ACTION = "payment/PARTIAL_AMOUNT_FORM_ACTION";
const partialAmountFormAction = action => ({
  type: PARTIAL_AMOUNT_FORM_ACTION,
  payload: action
});

const UPDATE_PAYMENT_MAXIMUM_IN_CENTS_FOR_PAYMENT_TYPE =
  "payment/UPDATE_PAYMENT_MAXIMUM_IN_CENTS_FOR_PAYMENT_TYPE";
export const updatePaymentMaximumInCentsForPaymentType = payload => ({
  type: UPDATE_PAYMENT_MAXIMUM_IN_CENTS_FOR_PAYMENT_TYPE,
  payload
});

const CLEAR_PAYMENT = "payment/CLEAR_PAYMENT";
export const clearPayment = () => ({
  type: CLEAR_PAYMENT
});

const RECEIVE_PROFILE_RESOURCES = "payment/RECEIVE_PROFILE_RESOURCES";
export const receiveProfileResources = resources => ({
  type: RECEIVE_PROFILE_RESOURCES,
  payload: resources
});

export const CONFIGURE_WALLET = "payment/CONFIGURE_WALLET";
export const configureWallet = walletEnabled => ({
  type: CONFIGURE_WALLET,
  payload: { walletEnabled }
});

const OPEN_WALLET_SLIDER = "payment/OPEN_WALLET_SLIDER";
export const openWalletSlider = () => ({
  type: OPEN_WALLET_SLIDER
});

const CLOSE_WALLET_SLIDER = "payment/CLOSE_WALLET_SLIDER";
export const closeWalletSlider = () => ({
  type: CLOSE_WALLET_SLIDER
});

const CHANGE_PANEL = "payment/CHANGE_PANEL";
export const changePanel = (panelName, panelStatus) => ({
  type: CHANGE_PANEL,
  payload: { panelName, panelStatus }
});

export const SAVE_TO_WALLET = "payment/SAVE_TO_WALLET";
export const saveToWallet = savedWalletData => ({
  type: SAVE_TO_WALLET,
  payload: savedWalletData
});

export const SEND_WALLET_RESOURCES = "payment/SEND_WALLET_RESOURCES";
export const sendWalletResources = () => ({
  type: SEND_WALLET_RESOURCES
});

export const SET_VERIFICATION_EMAIL = "payment/SET_VERIFICATION_EMAIL";
export const setVerificationEmail = email => ({
  type: SET_VERIFICATION_EMAIL,
  payload: { email }
});

export const navigateToCheckoutSubpage = ({
  serviceName,
  invoiceId,
  subpage
}) =>
  serviceName
    ? push(`/payment/service/${serviceName}/${subpage}`)
    : push(`/payment/invoice/${invoiceId}/${subpage}`);

const updatePayment = (state, updatedValues) =>
  assign("payment", updatedValues, state);

const updateInvoice = (state, updatedValue) =>
  set("invoice", updatedValue, state);

const updateVisitId = (state, updatedValue) =>
  set("visitId", updatedValue, state);

export const PAYMENT_AMOUNT_LOADING = "payment/PAYMENT_AMOUNT_LOADING";
const loadPaymentAmount = () => ({
  type: PAYMENT_AMOUNT_LOADING
});

export const PAYMENT_DETAILS_LOADING = "payment/PAYMENT_DETAILS_LOADING";
const loadPaymentDetails = () => ({
  type: PAYMENT_DETAILS_LOADING
});

const PAYMENT_LOADING_SUCCESS = "payment/PAYMENT_LOADING_SUCCESS";
export const paymentLoadingSuccess = () => ({
  type: PAYMENT_LOADING_SUCCESS
});

export const CREATE_PAYMENT_FROM_LOCAL_STORAGE =
  "payment/CREATE_PAYMENT_FROM_LOCAL_STORAGE";
export const createPaymentFromLocalStorage = storedPaymentPayload => ({
  type: CREATE_PAYMENT_FROM_LOCAL_STORAGE,
  payload: storedPaymentPayload
});

export const INITIATE_EXPIRED_SESSION = "payment/INITIATE_EXPIRED_SESSION";
export const initiateExpiredSession = () => ({
  type: INITIATE_EXPIRED_SESSION
});

export const SET_EXPIRED_URL = "payment/SET_EXPIRED_URL";
export const setExpiredURL = url => ({
  type: SET_EXPIRED_URL,
  payload: {
    url
  }
});

export const EXPIRE_PAYMENT_SESSION = "payment/EXPIRE_PAYMENT_SESSION";
export const expirePaymentSession = () => ({
  type: EXPIRE_PAYMENT_SESSION
});

export const SET_SESSION_FROM_LOCAL_STORAGE =
  "payment/SET_SESSION_FROM_LOCAL_STORAGE";
export const setSessionFromLocalStorage = session => ({
  type: SET_SESSION_FROM_LOCAL_STORAGE,
  payload: session
});

export const UPDATE_PROFILE_RESOURCES = "payment/UPDATE_PROFILE_RESOURCES";
export const updateProfileResources = resources => ({
  type: UPDATE_PROFILE_RESOURCES,
  payload: resources
});

export const SET_LOADING_PROFILE_RESOURCES =
  "payment/SET_LOADING_PROFILE_RESOURCES";
export const setLoadingProfileResources = isLoading => ({
  type: SET_LOADING_PROFILE_RESOURCES,
  payload: isLoading
});

export const RESET_EXPIRED_SESSION = "payment/RESET_EXPIRED_SESSION";
export const resetExpiredSession = () => ({
  type: RESET_EXPIRED_SESSION
});

// on payment success, clear payment line items, cart items
// so user can't refresh and make duplicate payment
// but keep info available for success page
export const CLEAR_PROCESSED_PAYMENT_INFO =
  "payment/CLEAR_PROCESSED_PAYMENT_INFO";
export const clearProcessedPaymentInfo = () => ({
  type: CLEAR_PROCESSED_PAYMENT_INFO
});

export const USE_CUSTOMER_INFORMATION_EMAIL =
  "payment/USE_CUSTOMER_INFORMATION_EMAIL";
export const useCustomerInformationEmail = payload => ({
  type: USE_CUSTOMER_INFORMATION_EMAIL,
  payload
});

const _reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // Use profile resources when they load here:
    // Currently just set saved info as selected
    // WARNING: There is a potential race condition if this happens AFTER
    // the user gets to Billing Info it'll select stuff on screen
    case RECEIVE_PROFILE_RESOURCES:
      const firstAddressId = payload?.person?.constituent?.addresses[0]?.id;
      const firstEmailId = payload?.person?.constituent?.contacts.filter(
        c => c.kind === "email"
      )[0]?.id;
      const firstPhoneId = payload?.person?.constituent?.contacts.filter(
        c => c.kind === "phone"
      )[0]?.id;
      return updatePayment(state, {
        newAddressSelected: firstAddressId ? false : true,
        selectedSavedAddress: {
          addressId: firstAddressId
        },
        newEmailSelected: firstEmailId ? false : true,
        selectedSavedEmail: {
          contactId: firstEmailId
        },
        newPhoneSelected: firstPhoneId ? false : true,
        selectedSavedPhone: {
          contactId: firstPhoneId
        },
        isLoadingProfileResources: false
      });
    // Fixes weird bug that happened when resources were loaded from cache
    case UPDATE_PROFILE_RESOURCES:
      const { addresses, emails, phoneNumbers } = payload;
      const addressId = Object.keys(addresses)?.[0] ?? null;
      const emailId = Object.keys(emails)?.[0] ?? null;
      const phoneId = Object.keys(phoneNumbers)?.[0] ?? null;
      return updatePayment(state, {
        newAddressSelected: !addressId,
        selectedSavedAddress: {
          addressId
        },
        newEmailSelected: !emailId,
        selectedSavedEmail: {
          contactId: emailId
        },
        newPhoneSelected: !phoneId,
        selectedSavedPhone: {
          contactId: phoneId
        }
      });
    case SET_LOADING_PROFILE_RESOURCES:
      return {
        ...state,
        payment: {
          ...state.payment,
          isLoadingProfileResources: payload
        }
      };
    case FETCH_INVOICE:
      return updateInvoice(state, S.RemoteData.Loading);
    case FETCH_INVOICE_SUCCESS:
      return updateInvoice(state, S.RemoteData.Success(""));
    case FETCH_INVOICE_FAILURE:
      return updateInvoice(state, S.RemoteData.Failure(payload));
    // TODO: I kind of hate this
    // this isn't a typo, I'm using fall-through on the Switch
    case POPULATE_PAYMENT_FROM_PROFILE:
    case CREATE_PAYMENT:
      const lineItemsWithIds = payload.lineItems.map(li => ({
        id: uuidv4(),
        ...li
      }));
      // Keep visitId out of localStorage
      const paymentPayloadWithoutVisit = R.dissoc("visitId", payload);
      return {
        ...set(
          "localStorage",
          paymentPayloadWithoutVisit,
          updatePayment(state, {
            ...newPaymentState(state),
            ...R.pick(
              [
                "newAddressSelected",
                "selectedSavedAddress",
                "newEmailSelected",
                "selectedSavedEmail",
                "newPhoneSelected",
                "selectedSavedPhone"
              ],
              state.payment
            ),
            customAttributes: payload.customAttributes,
            accountId: payload.accountId,
            lineItems: lineItemsWithIds,
            routingKey: payload.routingKey,
            subClientSlug: payload.subClientSlug,
            potentialFees: payload.fees,
            terms: payload.terms,
            paymentServiceContact: payload.contact || {},
            invoiceId: payload.invoiceId,
            traceNumber: !!payload.invoiceId ? payload.invoiceId : uuidv4(),
            isInvoice: !!payload.invoiceId,
            partialAmountForm: init(
              createPartialAmountFormState(
                lineItemsWithIds,
                payload.paymentMaximumInCents,
                payload.paymentMinimumInCents,
                payload.blockPartialPaymentOverpay
              ).partialAmountFormReducer
            ),
            paymentMaximumInCents: payload.paymentMaximumInCents,
            paymentMaximumInCentsForPaymentType: payload.paymentMaximumInCents,
            paymentMinimumInCents: payload.paymentMinimumInCents,
            paymentAmount: FULL_AMOUNT,
            serviceName: payload.serviceName,
            allowedPaymentMethods: payload.allowedPaymentMethods || [
              "bank",
              "card"
            ],
            allowBankAccountType: payload.allowBankAccountType || false,
            amountEnabled: R.isNil(payload.partialPaymentAllowed)
              ? true
              : payload.partialPaymentAllowed,
            returnURL: payload.returnURL,
            cancelURL: payload.cancelURL,
            isProfilePayment: payload?.isProfilePayment ?? false,
            partialPaymentMinimumInCents: payload?.partialPaymentMinimumInCents,
            blockPartialPaymentOverpay:
              payload?.blockPartialPaymentOverpay ?? false,
            accountLookupConfigKey: payload.accountLookupConfigKey,
            configForPaymentTypes: payload?.configForPaymentTypes,
            cartId: payload?.cartId,
            status: payload?.status
          })
        ),
        isExpiredSession: payload?.isExpiredSession ?? false,
        /*
        if coming from workflow, this sets visitId that is in the payload
        if coming from standard/profile, this wipes any pre-existing visitId
        which allows new visitId to be requested by checkout that matches account
        */
        visitId: {
          value: {
            visitId: payload?.visitId
          }
        }
      };
    case CLEAR_PAYMENT:
      return init(reducer);
    case GET_VISIT_ID:
      return updateVisitId(state, S.RemoteData.Loading);
    case VISIT_ID_SUCCESS:
      return updateVisitId(state, S.RemoteData.Success(payload));
    case VISIT_ID_FAILURE:
      return updateVisitId(state, S.RemoteData.Failure(payload));
    case SELECT_PAYMENT_AMOUNT:
      return {
        ...state,
        payment: {
          ...state.payment,
          paymentAmount: payload.paymentAmount
        }
      };
    case SELECT_NEW_PAYMENT_METHOD:
      return updatePayment(state, {
        selectedSavedCreditCardId: null,
        selectedSavedACHId: null,
        selectedPaymentMethodType: payload.methodType,
        termsAndConditionsAgreedTo: false
      });
    case SELECT_SAVED_CARD_METHOD:
      return updatePayment(state, {
        selectedSavedCreditCardId: payload.instrumentId,
        selectedSavedACHId: null,
        selectedPaymentMethodType: SAVED_CC,
        termsAndConditionsAgreedTo: false
      });
    case SELECT_SAVED_ACH_METHOD:
      return updatePayment(state, {
        selectedSavedACHId: payload.instrumentId,
        selectedSavedCreditCardId: null,
        selectedPaymentMethodType: SAVED_ACH,
        termsAndConditionsAgreedTo: false
      });
    case SELECT_SAVED_ADDRESS:
      return updatePayment(state, {
        newAddressSelected: false,
        selectedSavedAddress: {
          addressId: payload.addressId
        }
      });
    case SELECT_NEW_ADDRESS:
      return updatePayment(state, {
        newAddressSelected: true,
        selectedSavedAddress: {
          addressId: null
        }
      });
    case SELECT_SAVED_EMAIL:
      return updatePayment(state, {
        newEmailSelected: false,
        selectedSavedEmail: {
          contactId: payload.contactId
        }
      });
    case SELECT_NEW_EMAIL:
      return updatePayment(state, {
        newEmailSelected: true,
        selectedSavedEmail: {
          contactId: null
        }
      });
    case SELECT_SAVED_PHONE:
      return updatePayment(state, {
        newPhoneSelected: false,
        selectedSavedPhone: {
          contactId: payload.contactId
        }
      });
    case SELECT_NEW_PHONE:
      return updatePayment(state, {
        newPhoneSelected: true,
        selectedSavedPhone: {
          contactId: null
        }
      });
    case TOGGLE_TERMS_AND_CONDITIONS:
      return updatePayment(state, {
        termsAndConditionsAgreedTo: !state.payment.termsAndConditionsAgreedTo
      });
    case SUBMIT_PAYMENT:
      return updatePayment(state, {
        paymentSubmitting: true
      });
    case SUBMIT_PAYMENT_SUCCESS:
      return updatePayment(state, {
        paymentVerificationNumber: payload.paymentVerificationNumber,
        authCode: payload.authCode,
        serviceFeePaymentAuthCode: payload.serviceFeePaymentAuthCode,
        paymentSubmitting: false,
        paymentRedirecting: payload.paymentRedirecting,
        complete: true
      });
    case SUBMIT_PAYMENT_DUPLICATE:
      return updatePayment(state, {
        paymentSubmitting: false,
        complete: true,
        duplicate: true
      });
    case SUBMIT_PAYMENT_FAILURE:
      return updatePayment(state, {
        paymentSubmitting: false
      });
    case PARTIAL_AMOUNT_FORM_ACTION:
      return updatePayment(state, {
        partialAmountForm: createPartialAmountFormState(
          ...getPartialAmountFormPropsFromPayment(state.payment)
        ).partialAmountFormReducer(state.payment.partialAmountForm, payload)
      });
    case UPDATE_PAYMENT_MAXIMUM_IN_CENTS_FOR_PAYMENT_TYPE:
      return updatePayment(state, {
        paymentMaximumInCentsForPaymentType: payload
      });
    case CONFIGURE_WALLET:
      return {
        ...state,
        payment: {
          ...state.payment,
          walletEnabled: payload.walletEnabled
        },
        localStorage: {
          ...state.localStorage,
          walletEnabled: payload.walletEnabled
        }
      };
    case OPEN_WALLET_SLIDER:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          sliderOpen: true
        }
      };
    case CLOSE_WALLET_SLIDER:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          sliderOpen: false
        }
      };
    case CHANGE_PANEL:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          panels: {
            ...state.wallet.panels,
            [payload.panelName]: {
              status: payload.panelStatus
            }
          }
        }
      };
    case SAVE_TO_WALLET:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          savedWalletData: {
            ...state.wallet.savedWalletData,
            ...payload
          }
        }
      };
    case SET_VERIFICATION_EMAIL:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          walletEmail: payload.email
        }
      };
    case GUEST_CHECKOUT:
      return {
        ...state,
        payment: {
          ...state.payment,
          isGuestCheckout: true
        }
      };
    case CLEAR_PAYMENT_CONTACT_INFORMATION:
      return {
        ...state,
        payment: {
          ...state.payment,
          newAddressSelected: true,
          newPhoneSelected: true,
          newEmailSelected: true
        }
      };
    case SET_EXPIRED_URL:
      return {
        ...state,
        expiredURL: payload.url
      };
    case EXPIRE_PAYMENT_SESSION:
      return {
        ...state,
        localStorage: {
          ...state.localStorage,
          lineItems: [],
          fees: {}
        },
        payment: {
          ...state.payment,
          lineItems: [],
          potentialFees: {}
        },
        isExpiredSession: true
      };
    case SET_SESSION_FROM_LOCAL_STORAGE:
      return {
        ...state,
        isExpiredSession: payload?.isExpiredSession ?? false,
        expiredURL: payload?.expiredURL ?? { url: "", label: "" }
      };
    case RESET_EXPIRED_SESSION:
      return {
        ...state,
        isExpiredSession: false
      };
    case CLEAR_PROCESSED_PAYMENT_INFO:
      return {
        ...state,
        localStorage: {
          ...state.localStorage,
          processedLineItems: state.localStorage.lineItems,
          lineItems: []
        },
        payment: {
          ...state.payment,
          processedLineItems: state.payment.lineItems,
          lineItems: []
        }
      };
    case PAYMENT_AMOUNT_LOADING:
      return {
        ...state,
        paymentAmountIsLoading: true
      };
    case PAYMENT_DETAILS_LOADING:
      return {
        ...state,
        paymentDetailsIsLoading: true
      };
    case PAYMENT_LOADING_SUCCESS:
      return {
        ...state,
        paymentAmountIsLoading: false,
        paymentDetailsIsLoading: false
      };
    case USE_CUSTOMER_INFORMATION_EMAIL:
      return {
        ...state,
        usesCustomerInformationEmail: payload
      };
    default:
      return state;
  }
};

const _mapStateToProps = (state, props) => {
  const payment = state.checkout.payment;
  const captchaSiteKey = state.config.captchaSiteKey;
  const visitIdClientConfigError =
    state?.checkout?.visitId?.value?.errorCode === VISIT_ID_CONFIG_ERROR;
  return {
    profileResources: getProfileResources(PROFILE_KEY)(state),
    savedCreditCards: getSavedCreditCards(PROFILE_KEY)(state),
    savedBankAccounts: getSavedBankAccounts(PROFILE_KEY)(state),
    savedAddresses: getSavedAddresses(PROFILE_KEY)(state),
    savedContactEmails: getSavedEmails(PROFILE_KEY)(state),
    savedContactPhone: getSavedPhoneNumbers(PROFILE_KEY)(state),
    captchaSiteKey,
    walletEnabled: state.global.settings.data.walletEnable,
    invoice: state.checkout.invoice,
    payment: !payment.initialized
      ? {}
      : {
          ...payment,
          paymentDetailsLineItems: getLineItems(state),
          processedLineItems: getProcessedLineItems(state),
          payerName: getPayerName(state, props),
          subtotal: getSubtotal(state, props),
          fees: getCalculatedFees(state, props),
          feesTotal: getFeesTotal(state, props),
          total: getTotal(state, props),
          fullAmountTotal: getFullAmountTotal(state, props),
          partialAmountFormProps: getPartialAmountFormProps(state),
          partialAmountForm: createPartialAmountFormState(
            ...getPartialAmountFormProps(state)
          ).partialAmountFormMapStateToProps(payment.partialAmountForm),
          savedCreditCard: getSavedCard(state, props),
          savedACH: getSavedBank(state, props),
          billingAddress: getBillingAddress(state, props),
          contactPhone: getPhoneNumber(state),
          contactEmail: getEmailAddress(state),
          termsAndConditions: getTermsAndConditions(state, props),
          fullAmountSelected: payment.paymentAmount === FULL_AMOUNT,
          partialAmountSelected: payment.paymentAmount === PARTIAL_AMOUNT,
          allowFullPayment: getAllowFullPayment(state, props)
        },
    wallet: {
      ...state.checkout.wallet,
      submitting: state.login.submitting || state.forgotPassword.submitting
    },
    paymentConfigurations: state.global.payment.data,
    expiredURL: state.checkout.expiredURL,
    isExpiredSession: state.checkout.isExpiredSession,
    cartEnabled:
      state.global?.clientMetadata?.data?.shoppingCartEnabled ?? false,
    cartsCount: state?.cart?.carts
      ? Object.entries(state.cart.carts).length
      : 0,
    visitId: state?.checkout?.visitId?.value?.visitId,
    visitIdLoading: state?.checkout?.visitId?.isLoading,
    visitIdRequestFailure:
      state?.checkout?.visitId?.isFailure && !visitIdClientConfigError,
    hasVisitIdConfigError: visitIdClientConfigError,
    paymentAmountIsLoading: state?.checkout?.paymentAmountIsLoading,
    paymentDetailsIsLoading: state?.checkout?.paymentDetailsIsLoading,
    usesCustomerInformationEmail: state?.checkout?.usesCustomerInformationEmail
  };
};

const _mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchInvoice,
      clearPayment,
      fetchResources,
      navigateToProfile: () => push("/profile/accounts"),
      configureWallet,
      fetchSubClientConfiguration,
      getVisitId,
      openCartSlider,
      useCustomerInformationEmail
    },
    dispatch
  ),
  dispatch: dispatch,
  subPageActions: {
    ...bindActionCreators(
      {
        partialAmountFormAction,
        selectPartialAmount,
        selectFullAmount,
        selectNewACHPaymentMethod,
        selectNewCreditCardPaymentMethod,
        selectSavedCardMethod,
        selectSavedAchMethod,
        selectNewAddress,
        selectSavedAddress,
        selectNewEmail,
        selectSavedEmail,
        selectNewPhone,
        selectSavedPhone,
        toggleTermsAndConditions,
        submitPayment,
        navigateToCheckoutSubpage,
        openWalletSlider,
        closeWalletSlider,
        changePanel,
        submitLoginForm,
        saveToWallet,
        resendVerification,
        guestCheckout,
        submitForgotPasswordForm,
        clearPaymentContactInformation,
        updatePaymentMaximumInCentsForPaymentType,
        loadPaymentAmount,
        loadPaymentDetails
      },
      dispatch
    )
  }
});

const ACH_FORM_ACTION = "payment/ACH_FORM_ACTION";
const CREDIT_CARD_FORM_ACTION = "payment/CREDIT_CARD_FORM_ACTION";
const ADDRESS_FORM_ACTION = "payment/ADDRESS_FORM_ACTION";
const EMAIL_FORM_ACTION = "payment/EMAIL_FORM_ACTION";
const PHONE_FORM_ACTION = "payment/PHONE_FORM_ACTION";
const ALERT_BAR_ACTION = "payment/ALERT_BAR_ACTION";
const CAPTCHA_FORM_ACTION = "payment/CAPTCHA_FORM_ACTION";
export const alertBarAction = Nested.nestedAction(ALERT_BAR_ACTION);

export const mergeProps = (state, actions, ownProps) => {
  if (R.isEmpty(state.payment)) {
    return {
      ...state,
      ...actions,
      ...ownProps
    };
  } else {
    const partialAmountFormActions = R.evolve(
      {
        actions: {
          fields: R.map(
            R.map(actionFn => (...args) =>
              actions.dispatch(partialAmountFormAction(actionFn(...args)))
            )
          ),
          form: R.map(actionFn => (...args) =>
            actions.dispatch(partialAmountFormAction(actionFn(...args)))
          )
        }
      },
      createPartialAmountFormState(
        state.payment.lineItems
      ).partialAmountFormMapDispatchToProps(R.identity)
    );
    const updatedActions = {
      ...actions,
      actions: {
        ...actions.actions,
        forms: {
          ...actions.actions.forms,
          partialAmountForm: partialAmountFormActions
        }
      }
    };
    return {
      ...state,
      ...updatedActions,
      ...ownProps
    };
  }
};

const {
  reducer: nestedReducer,
  mapStateToProps,
  mapDispatchToProps,
  actions
} = Nested.nestStates(
  {
    reducer: _reducer,
    mapStateToProps: _mapStateToProps,
    mapDispatchToProps: _mapDispatchToProps
  },
  {
    forms: {
      achForm: {
        ...PaymentFormACH,
        actionType: ACH_FORM_ACTION
      },
      creditCardForm: {
        ...PaymentFormCard,
        actionType: CREDIT_CARD_FORM_ACTION
      },
      addressForm: {
        ...AddressForm,
        actionType: ADDRESS_FORM_ACTION
      },
      phoneForm: {
        ...PhoneForm,
        actionType: PHONE_FORM_ACTION
      },
      emailForm: {
        ...EmailForm,
        actionType: EMAIL_FORM_ACTION
      },
      captchaForm: {
        ...CaptchaForm,
        actionType: CAPTCHA_FORM_ACTION
      },
      walletLoginForm: {
        ...LoginForm,
        actionType: WALLET_LOGIN_FORM_ACTION
      },
      walletForgotPasswordForm: {
        ...ForgotPasswordForm,
        actionType: WALLET_FORGOT_PASSWORD_FORM_ACTION
      }
    },
    alerts: {
      ...AlertBarState,
      actionType: ALERT_BAR_ACTION
    },
    walletLoginAlertBar: {
      ...AlertBarState,
      actionType: WALLET_LOGIN_ALERT_BAR_ACTION
    },
    walletVerifyAlertBar: {
      ...AlertBarState,
      actionType: WALLET_VERIFY_ALERT_BAR_ACTION
    },
    walletForgotPasswordAlertBar: {
      ...AlertBarState,
      actionType: WALLET_FORGOT_PASSWORD_ALERT_BAR_ACTION
    }
  },
  "checkout"
);

/*
  Takes the current checkout state and wipes out any form values on checkout forms
  Used for checkout session expiration
  We need to preserve certain parts of checkout state after expiration in order to properly direct the user
  But we don't want to keep potentially sensitive data around
*/
const sanitizeCheckoutState = state => {
  const cleanedForms = Object.entries(state.forms).reduce(
    (acc, [formName, form]) => {
      const cleanedProperties = Object.entries(form).reduce(
        (acc, [propertyName, property]) => {
          return {
            ...acc,
            [propertyName]: {
              ...property,
              rawValue: ""
            }
          };
        },
        {}
      );

      return {
        ...acc,
        [formName]: {
          ...cleanedProperties
        }
      };
    },
    {}
  );

  return {
    ...state,
    forms: cleanedForms
  };
};

const reducer = (state, action) => {
  /*
    Run the cleaning function when payment session expires (authenticated and unauthenticated)
    On logout, if expired session, use the sanitized state instead of undefined
    This prevents overwriting the cleaned state with the initial state of the reducer
    Which lets us keep isExpiredSession and expiredURL around
  */
  const sanitizedStateWithSession =
    action.type === EXPIRE_PAYMENT_SESSION
      ? {
          ...sanitizeCheckoutState(state),
          isExpiredSession: state?.isExpiredSession ?? false,
          expiredURL: state?.expiredURL ?? { url: "", label: "" }
        }
      : state;
  const clearedState =
    state !== undefined && action.type === LOGOUT && state.isExpiredSession
      ? sanitizedStateWithSession
      : undefined;
  return action.type === LOGOUT || action.type === CLEAR_STATE
    ? nestedReducer(clearedState, "@@INIT")
    : nestedReducer(sanitizedStateWithSession, action);
};

export { reducer, mapStateToProps, mapDispatchToProps, actions };
