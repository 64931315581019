import React, { useEffect, Fragment, useState, useRef, createRef } from "react";
import { Box, Stack } from "../layouts";
import Text from "../text";
import {
  noop,
  inputDisabledStyle,
  inputPlaceholderTextStyle
} from "../../../util/general";
import DropdownIcon from "./DropdownIcon";
import styled from "styled-components";
// support for Array.prototype.at() in older browsers
import "core-js/proposals/relative-indexing-method";

import {
  ERROR_COLOR,
  GREY_CHATEAU,
  MINESHAFT_GREY,
  STORM_GREY,
  WHITE
} from "../../../constants/colors";
import { ENTER } from "../../../constants/keyboard";
import { fallbackValues } from "./Dropdown.theme";
import { themeComponent } from "../../../util/themeUtils";

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s ease;
  ${({ open }) => (open ? "transform: rotate(-180deg)" : "")};
  top: 20px;
  right: 12px;
`;

const DropdownContentWrapper = styled.div`
  transform-origin: 0 0;
  border: 1px solid ${GREY_CHATEAU};
  border-radius: 2px;
  background-color: ${WHITE};
  padding: 8px 0 8px;
  position: absolute;
  width: ${({ widthFitOptions }) => (widthFitOptions ? "fit-content" : "100%")};
  min-width: 100%;
  max-height: ${({ maxHeight }) => maxHeight || "400px"};
  overflow-y: scroll;
  z-index: 1;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }

  ul {
    padding-left: 0;
  }
`;

const DropdownItemWrapper = styled.li`
  text-align: start;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ selected, themeValues }) =>
    selected ? themeValues.selectedColor : WHITE};
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  list-style: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  &:hover {
    border-color: ${({ disabled, selected, themeValues }) =>
      selected
        ? themeValues.focusColor
        : disabled
        ? WHITE
        : themeValues.hoverColor};
    > * {
      background: ${({ selected, disabled, themeValues }) =>
        selected
          ? themeValues.focusColor
          : disabled
          ? WHITE
          : themeValues.hoverColor};
      border-color: ${({ selected, disabled, themeValues }) =>
        selected
          ? themeValues.focusColor
          : disabled
          ? WHITE
          : themeValues.hoverColor};
    }
  }
  &:focus {
    outline: none;
    border-color: ${({ themeValues }) => themeValues.selectedColor};
    > * {
      background: ${({ selected, disabled, themeValues }) =>
        selected
          ? themeValues.focusColor
          : disabled
          ? WHITE
          : themeValues.hoverColor};
      border-color: white;
      outline: none;
    }
  }
`;

const DropdownItemBorder = styled.div`
  background: ${({ selected, themeValues }) =>
    selected ? themeValues.selectedColor : WHITE};
  border-color: ${({ selected, themeValues }) =>
    selected ? themeValues.selectedColor : WHITE};
  border-width: 2px;
  border-style: solid;
  padding: 12px;
`;

const Dropdown = ({
  placeholder,
  options,
  value,
  isOpen,
  isError,
  onSelect,
  disabledValues = [],
  onClick = noop,
  themeValues,
  maxHeight,
  widthFitOptions = false,
  disabled,
  hasTitles = false,
  autoEraseTypeAhead = true,
  ariaLabelledby,
  ariaDescribedby,
  autocompleteValue, // browser autofill value, like country-name
  smoothScroll = true,
  ariaInvalid = false,
  isRequired = false
}) => {
  const [inputValue, setInputValue] = useState("");
  const [optionsState, setOptionsState] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [optionsChanged, setOptionsChanged] = useState(true);
  const [selectedRef, setSelectedRef] = useState(undefined);
  const [focusedRef, setFocusedRef] = useState(undefined);
  const [inputChangedByAutofill, setInputChangedByAutofill] = useState(false);
  const [focusedByClick, setFocusedByClick] = useState(false);

  if (optionsState !== options) {
    setOptionsState(options);
    setOptionsChanged(true);
  }

  if (optionsChanged) {
    setFilteredOptions(options);
    setOptionsChanged(false);
  }

  const [timer, setTimer] = useState(null);
  const optionRefs = useRef([...Array(options.length)].map(() => createRef()));
  const dropdownRef = useRef(null);

  const getSelection = () =>
    value ? options.find(option => option.value === value)?.text : placeholder;

  const onKeyDown = e => {
    const { key, keyCode } = e;
    const focus = document.activeElement;
    const optionEl = optionRefs.current.find(ref => ref.current === focus);
    switch (key) {
      case "ArrowDown":
        e.preventDefault();
        if (!isOpen) {
          onClick();
        }
        if (optionEl) {
          if (optionEl.current.nextElementSibling) {
            optionEl.current.nextElementSibling.focus();
          } else {
            // If at the end of the options list, don't do anything
            break;
          }
        } else {
          onClick();
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (optionEl) {
          if (optionEl.current.previousElementSibling) {
            optionEl.current.previousElementSibling.focus();
          } else {
            // If at the start of the options list, don't do anything
            break;
          }
        } else {
          onClick();
        }
        break;
      case "Tab":
        if (isOpen) {
          e.preventDefault();
        }
        break;
      case "Backspace" || "Delete":
        e.preventDefault();
        setInputValue(inputValue.slice(0, -1));
        break;
      case "Home":
        e.preventDefault();
        optionRefs.current[0].current.focus();
        break;
      case "End":
        e.preventDefault();
        optionRefs.current.at(-1).current.focus();
        break;
      case "Escape":
        if (isOpen) {
          onClick();
        }
        break;
    }
    if ((keyCode > 64 && keyCode < 91) || keyCode == 32 || keyCode == 189) {
      e.preventDefault();
      setInputValue(inputValue + key);
    }
  };

  const handleItemSelection = (evt, choice, i) => {
    if (disabledValues.includes(choice.value)) {
      evt.preventDefault();
    } else {
      setSelectedRef(optionRefs.current[i]);
      onSelect(choice.value);
      if (isOpen) {
        onClick();
      }
    }
  };

  useEffect(() => {
    const selectedRefExists =
      selectedRef !== undefined && selectedRef.current !== null;
    if (isOpen && selectedRefExists && !focusedByClick) {
      // For keyboard users, WAI-ARIA requires the selected option to receive focus
      selectedRef.current.focus();
    } else if (isOpen && optionRefs.current[0].current && !focusedByClick) {
      // If no selected option, first option receives focus
      optionRefs.current[0].current.focus();
    }
    if (isOpen && focusedByClick && selectedRefExists) {
      // To support autofill for mouse users, we maintain focus on input just scroll item into view
      selectedRef.current.scrollIntoView({
        behavior: smoothScroll ? "smooth" : "auto",
        block: "nearest",
        inline: "start"
      });
      setFocusedByClick(false);
    }
    clearTimeout(timer);
    setInputValue("");
  }, [isOpen]);

  useEffect(() => {
    if (autoEraseTypeAhead) {
      clearTimeout(timer);
      setTimer(setTimeout(() => setInputValue(""), 20000));
    }

    setFilteredOptions(
      options.filter(
        option =>
          option?.value?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) >=
            0 ||
          option.text?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) >= 0
      )
    );
  }, [inputValue]);

  useEffect(() => {
    if (
      /*
        Either user has typed a value into input that matches a non-disabled option or
        user has autofilled or pasted into input a string matching a valid option
      */
      (!isOpen || inputChangedByAutofill) &&
      filteredOptions[0] &&
      !disabledValues.includes(filteredOptions[0].value) &&
      filteredOptions[0].text != placeholder
    ) {
      setInputChangedByAutofill(false);
      onSelect(filteredOptions[0].value);
      if (isOpen) {
        setTimeout(() => onClick(), 1000);
      }
    }
    if (optionRefs.current[0].current) {
      optionRefs.current[0].current.focus();
    } else if (isOpen) {
      dropdownRef.current.focus();
    }
  }, [filteredOptions]);

  return (
    <Box
      padding="0"
      background={isOpen ? themeValues.hoverColor : WHITE}
      extraStyles={`position: relative;`}
      minWidth="100%"
      onClick={() => {
        if (!isOpen) {
          setFocusedByClick(true);
          onClick();
        }
      }}
      onKeyDown={onKeyDown}
      width="100%"
      dataQa={`${ariaLabelledby}-dropdown`}
    >
      <Box
        as="input"
        autoComplete={autocompleteValue}
        aria-controls={`${ariaLabelledby}_listbox`}
        aria-activedescendant={isOpen ? "focused_option" : undefined}
        aria-owns={`${ariaLabelledby}_listbox`}
        aria-haspopup="listbox"
        aria-labelledby={ariaLabelledby}
        aria-describedby={ariaDescribedby}
        aria-expanded={isOpen}
        aria-required={options.required}
        aria-invalid={ariaInvalid}
        background={isOpen ? themeValues.hoverColor : WHITE}
        borderRadius="2px"
        borderSize="1px"
        borderColor={
          isError
            ? ERROR_COLOR
            : isOpen
            ? themeValues.selectedColor
            : GREY_CHATEAU
        }
        dataQa={placeholder}
        extraStyles={
          disabled
            ? `${inputPlaceholderTextStyle}${inputDisabledStyle}`
            : inputPlaceholderTextStyle
        }
        hoverStyles={`background-color: ${themeValues.hoverColor};`}
        isOpen={isOpen}
        minHeight="48px"
        minWidth="100%"
        name={autocompleteValue}
        onChange={e => {
          // support autofill and copy/paste
          if (e.target.value !== inputValue) {
            setInputValue(e.target.value);
            setInputChangedByAutofill(true);
          }
        }}
        padding="12px 25px 12px 12px"
        placeholder={getSelection()}
        required={options.required || isRequired}
        role="combobox"
        themeValues={themeValues}
        title={hasTitles ? getSelection() : null}
        type="text"
        tabIndex={0}
        value={inputValue}
        width="100%"
      />
      <IconWrapper open={isOpen} onClick={onClick}>
        <DropdownIcon />
      </IconWrapper>
      <Fragment>
        {isOpen ? (
          <DropdownContentWrapper
            maxHeight={maxHeight}
            open={isOpen}
            ref={dropdownRef}
            widthFitOptions={widthFitOptions}
            tabIndex={0}
            role="listbox"
            id={`${ariaLabelledby}_listbox`}
          >
            <Stack childGap="0" as="ul">
              {filteredOptions.map((choice, i) => {
                if (
                  choice.value === value &&
                  selectedRef !== optionRefs.current[i]
                ) {
                  setSelectedRef(optionRefs.current[i]);
                }
                return (
                  <DropdownItemWrapper
                    id={
                      focusedRef === optionRefs.current[i]
                        ? "focused_option"
                        : choice.value
                    }
                    key={choice.value}
                    ref={optionRefs.current[i]}
                    tabIndex={-1}
                    onClick={e => handleItemSelection(e, choice, i)}
                    onKeyDown={e => {
                      if (e.keyCode === ENTER) {
                        handleItemSelection(e, choice, i);
                      }
                    }}
                    selected={choice.value === value}
                    aria-selected={choice.value === value}
                    disabled={disabledValues.includes(choice.value)}
                    aria-disabled={disabledValues.includes(choice.value)}
                    data-qa={choice.text}
                    themeValues={themeValues}
                    title={hasTitles ? choice.text : null}
                    role="option"
                    onFocus={() => setFocusedRef(optionRefs.current[i])}
                  >
                    <DropdownItemBorder
                      disabled={disabledValues.includes(choice.value)}
                      selected={choice.value === value}
                      themeValues={themeValues}
                    >
                      <Text
                        variant="p"
                        color={
                          choice.value === value
                            ? WHITE
                            : disabledValues.includes(choice.value)
                            ? STORM_GREY
                            : MINESHAFT_GREY
                        }
                        extraStyles={`padding-left: 16px;
                        cursor: ${
                          disabledValues.includes(choice.value)
                            ? "default"
                            : "pointer"
                        };
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;`}
                      >
                        {choice.text}
                      </Text>
                    </DropdownItemBorder>
                  </DropdownItemWrapper>
                );
              })}
            </Stack>
          </DropdownContentWrapper>
        ) : (
          <Fragment />
        )}
      </Fragment>
    </Box>
  );
};

export default themeComponent(Dropdown, "Dropdown", fallbackValues);
