import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Modal,
  ButtonWithAction,
  ToggleSwitch,
  Box,
  util
} from "@thecb/components";

export const AutopayLink = (
  autoPayActive,
  toggleModal,
  isMobile,
  disabled = false
) =>
  autoPayActive ? (
    <Box padding="0px">
      <ToggleSwitch
        isOn={autoPayActive}
        label={"On"}
        onToggle={() => {
          disabled ? util.general.noop : toggleModal(true);
        }}
        isMobile={isMobile}
        dataQa="Autopay Toggle"
        disabled={disabled}
      />
    </Box>
  ) : (
    <ButtonWithAction
      text="Set Up Autopay"
      variant="ghost"
      action={() => {
        disabled ? util.general.noop : toggleModal(true);
      }}
      dataQa="Set Up Autopay"
      textExtraStyles={
        isMobile ? `font-size: 1.094rem; line-height: 1.641rem;` : ``
      }
      width="100%"
      extraStyles={`margin: 0;`}
      disabled={disabled}
    />
  );

export const AccountDetailsModal = ({
  autoPayActive,
  autoPaySchedule,
  toggleModal,
  modalOpen,
  modalDestination,
  deactivatePaymentSchedule,
  isMobile,
  disabled = false
}) => {
  let navigate = useNavigate();
  const modalExtraProps = {
    modalHeaderText: autoPayActive ? "Stop autopay" : "Set up autopay",
    modalBodyText: autoPayActive
      ? `Are you sure you want to stop autopay?`
      : "To set up autopay you must save a payment method and address. Do you want to save these now?",
    continueButtonText: autoPayActive ? "Stop Autopay" : "Add",
    useDangerButton: autoPayActive,
    continueAction: disabled
      ? util.general.noop
      : autoPayActive
      ? () => {
          deactivatePaymentSchedule(autoPaySchedule);
          toggleModal(false);
        }
      : () => {
          navigate(modalDestination);
        }
  };

  return (
    <Modal
      showModal={disabled ? util.general.noop : () => toggleModal(true)}
      hideModal={disabled ? util.general.noop : () => toggleModal(false)}
      modalOpen={modalOpen}
      {...modalExtraProps}
    >
      {AutopayLink(autoPayActive, toggleModal, isMobile, disabled)}
    </Modal>
  );
};

export const RemoveAccountModal = ({
  agency,
  obligations,
  deleteObligationAssoc,
  accountType,
  assocID,
  isMobile,
  detailedObligation,
  disabled = false
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const lastItem = [...obligations].pop();
  const accounts = obligations.reduce((acc, curr) => {
    const account = curr.details.description;
    const formattedAccount =
      curr !== lastItem ? `${account} and ` : `${account}`;
    return formattedAccount === agency ? agency : acc + formattedAccount;
  }, `${agency} - `);
  const identifier =
    accountType === "Account" && obligations.length > 1
      ? "accounts"
      : accountType === "Property" && obligations.length > 1
      ? "properties"
      : accountType.toLowerCase();
  const obligationSlug = detailedObligation?.config?.obligationSlug;

  return (
    <Modal
      showModal={() => setModalIsOpen(true)}
      hideModal={() => setModalIsOpen(false)}
      modalOpen={modalIsOpen}
      modalHeaderText={`Remove ${accountType}`}
      modalBodyText={`Are you sure you want to remove the ${identifier} ${accounts}? Any autopay scheduled against ${
        obligations.length > 1 ? "them" : "it"
      } will be deactivated.`}
      continueButtonText="Remove"
      continueAction={
        disabled
          ? util.general.noop
          : () => {
              deleteObligationAssoc({ id: assocID, obligationSlug });
              setModalIsOpen(false);
            }
      }
      useDangerButton
    >
      <ButtonWithAction
        text="Remove This Account"
        action={disabled ? util.general.noop : () => setModalIsOpen(true)}
        dataQa="Remove This Account"
        textExtraStyles={!isMobile && `font-weight: 600;`}
        variant="smallGhost"
        disabled={disabled}
      />
    </Modal>
  );
};
