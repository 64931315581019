import * as RxOp from "rxjs/operators";
import * as Rx from "rxjs";
import { combineEpics } from "redux-observable";
import {
  ADD_TO_SHOPPING_CART,
  CHECKOUT_FROM_CART,
  openCartSlider,
  closeCartSlider,
  populateCartPaymentConfig
} from "./ShoppingCart.state";
import {
  getCartPaymentConfig,
  getSubClientPaymentConfig,
  getPaymentConfigCustomAttrs,
  getPaymentConfigOptions,
  getPaymentConfigServiceName,
  getCartLineItems,
  getCartAccountNumber,
  getVisitIdFromCart,
  getAccountLookupConfigKey
} from "./ShoppingCart.selectors";
import { createPayment } from "../payment/Payment.state";

const populatePaymentConfigEpic = (action$, state$) =>
  action$.ofType(ADD_TO_SHOPPING_CART).pipe(
    RxOp.flatMap(() => {
      return getCartPaymentConfig(state$.value) === null
        ? Rx.of(
            populateCartPaymentConfig(getSubClientPaymentConfig(state$.value))
          )
        : Rx.EMPTY;
    })
  );

const openCartAfterItemsAddedEpic = action$ =>
  action$.ofType(ADD_TO_SHOPPING_CART).pipe(
    RxOp.flatMap(() => {
      return Rx.of(openCartSlider());
    })
  );

const checkoutFromCartEpic = (action$, state$) =>
  action$.ofType(CHECKOUT_FROM_CART).pipe(
    RxOp.flatMap(() =>
      Rx.of(
        closeCartSlider(),
        createPayment({
          lineItems: getCartLineItems(state$.value),
          serviceName: getPaymentConfigServiceName(state$.value),
          customAttributes: getPaymentConfigCustomAttrs(state$.value),
          accountId: getCartAccountNumber(state$.value),
          visitId: getVisitIdFromCart(state$.value),
          accountLookupConfigKey: getAccountLookupConfigKey(state$.value),
          ...getPaymentConfigOptions(state$.value)
        })
      )
    )
  );

const CartEpic = combineEpics(
  populatePaymentConfigEpic,
  openCartAfterItemsAddedEpic,
  checkoutFromCartEpic
);
export default CartEpic;
