import React, { useEffect, useLayoutEffect, Fragment, useContext } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Navigate
} from "react-router-dom";
import { matchRoutes } from "react-router";
import {
  CHECKOUT_PAYMENT,
  CHECKOUT_INFO,
  CHECKOUT_CONFIRM,
  CHECKOUT_WALLET,
  CHECKOUT_EXPIRED_SESSION,
  buildRoutes,
  checkoutLandingPage
} from "../../../../util/router-utils";
import { routes, withCommonSubpageArgs } from "./routes";
import { test, cond, or, and, always, T } from "ramda";
import {
  Alert,
  withWindowSize,
  HighlightTabRow,
  PaymentDetails,
  Box,
  Loading,
  Paragraph,
  Stack,
  constants
} from "@thecb/components";
import AlertBar from "../../../../components/alert-bar";
import { WholeBodyErrorWrapper } from "./Payment.styled";
import PaymentSuccess from "./sub-pages/payment-success";
import { ThemeContext } from "styled-components";
import PaymentProgress from "./sub-pages/payment-progress";
import { InvalidInvoiceStatusModal } from "./sub-pages/payment-wallet/InvalidInvoiceStatusModal";
import { expiredSessionPaths } from "./constants";
import { URL_TEST } from "/constants/regex_constants";
import { showTestSiteAlert } from "../../../../util/domain";
import { formatName } from "../../../../util/general";
import { isInvoicePending } from "../../../../util/paymentUtils";
import {
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_SEMIBOLD
} from "../../../../constants/style_constants";

const Payment = ({
  showLogout: isLoggedIn,
  profileResources,
  savedCreditCards,
  savedBankAccounts,
  savedAddresses,
  savedContactEmails,
  savedContactPhone,
  fetchResources,
  navigateToProfile,
  clearPayment,
  payment,
  subPageActions,
  forms,
  actions,
  alerts,
  fetchInvoice,
  invoice,
  captchaSiteKey,
  hasPaymentFlag,
  configureWallet,
  walletEnabled,
  wallet,
  walletLoginAlertBar,
  walletVerifyAlertBar,
  walletForgotPasswordAlertBar,
  fetchSubClientConfiguration,
  paymentConfigurations,
  expiredURL,
  isExpiredSession,
  cartEnabled,
  cartsCount,
  openCartSlider,
  handleFocusErrors,
  getVisitId,
  visitId,
  visitIdLoading,
  visitIdRequestFailure,
  hasVisitIdConfigError,
  paymentAmountIsLoading,
  paymentDetailsIsLoading,
  multiCartEnabled,
  walletScreenV2Enabled,
  usesCustomerInformationEmail,
  useCustomerInformationEmail
}) => {
  let params = useParams();
  let location = useLocation();
  let navigate = useNavigate();
  const { serviceName, invoiceId } = params;
  const expiredSessionTestPaths = expiredSessionPaths.map(path => ({
    path
  }));
  const onExpiredSessionPage =
    matchRoutes(expiredSessionTestPaths, location) !== null;
  const profilePayment = payment?.isProfilePayment ?? false;
  const guidedPayment = !profilePayment && !!serviceName; // aka workflow payment
  const standardPayment = !profilePayment && !guidedPayment; // aka invoice payment
  // lineItems are empty (successful payment) or completely undefined (something wrong)
  const lineItemsEmpty = payment?.lineItems?.length === 0 || !payment.lineItems;
  // created after successful payment
  const processedLineItemsExist = payment?.processedLineItems?.length > 0;
  // line items are empty, *not* because of successful payment (something wrong)
  const onlyEmptyLineItems =
    lineItemsEmpty && !processedLineItemsExist && !standardPayment; // standard payments can't get in this state
  const defaultReturnLocation =
    payment?.returnURL?.url || payment?.cancelURL?.url;
  const returnDest = profilePayment
    ? `/profile`
    : guidedPayment
    ? `/service/${serviceName}`
    : defaultReturnLocation;

  useLayoutEffect(() => {
    // using browser back button after successful payment
    if (lineItemsEmpty && processedLineItemsExist && !isExpiredSession) {
      // standard (aka invoice) payments send user outside of NFE when complete
      if (standardPayment) {
        window.location = returnDest;
      } else {
        navigate(returnDest);
      }
    }
    // using browser back button after session expires
    if (isExpiredSession && !onExpiredSessionPage && !invoiceId) {
      // return/cancel URLs for guided payments can be external or internal (NFE) locations
      if (test(URL_TEST, expiredURL.url)) {
        window.location = expiredURL.url;
      } else {
        navigate(expiredURL.url);
      }
    }
  }, [location.pathname]);
  const settingsFetched = profileResources.isSuccess;
  const invoiceFetchRequired = !!invoiceId && invoice.isNotAsked;
  const invoiceIsLoading = invoice?.isLoading;
  const visitIdFetchRequired =
    !visitId && !(visitId?.isSuccess || visitId?.isFailure);

  const { isMobile, mobileWidth, supportsTouch } = useContext(ThemeContext);

  useEffect(() => () => clearPayment(), []);

  useEffect(() => {
    if (isLoggedIn && !settingsFetched) {
      fetchResources();
    }
    if (invoiceFetchRequired) {
      fetchInvoice({ invoiceId });
    }
  }, [settingsFetched, isLoggedIn, invoiceFetchRequired, invoiceId]);

  const accountId = payment?.accountId;

  useEffect(() => {
    if (
      accountId &&
      visitIdFetchRequired &&
      !invoiceFetchRequired &&
      !invoiceIsLoading
    ) {
      getVisitId();
    }
  }, [accountId, visitIdFetchRequired, invoiceFetchRequired, invoiceIsLoading]);

  useEffect(() => {
    // If client lookup in GQLS incorrectly configured
    if (hasVisitIdConfigError) {
      console.warn(
        `%cGQLS Visit Id Client Config Error for subClientSlug: ${payment?.subClientSlug}, routingKey: ${payment?.routingKey}`,
        "background-color: rgba(235, 64, 52, 0.25); color: #eb4034; display: inline-block; margin: 0.5rem; padding: 0.5rem; border: 1px solid #eb4034;"
      );
    }
  }, [hasVisitIdConfigError]);

  useEffect(() => {
    configureWallet(walletEnabled);
  }, []);

  useEffect(() => {
    const subClientSlug = payment.subClientSlug || payment.serviceName;
    if (!isExpiredSession && !lineItemsEmpty && subClientSlug) {
      fetchSubClientConfiguration(subClientSlug);
    }
  }, [payment.subClientSlug, payment.serviceName]);

  const INVOICE_PENDING = "invoice_pending";
  const INVOICE_FETCH_ERROR = "invoice_error";
  const IN_PROGRESS = "in_progress";
  const SUCCESS = "success";
  const EXIT_CHECKOUT = "exit_checkout"; // something went wrong, need to bail out
  const checkoutPhase = cond([
    [
      always(or(invoiceFetchRequired, invoice.isLoading)),
      always(INVOICE_PENDING)
    ],
    [always(and(!!invoiceId, invoice.isFailure)), always(INVOICE_FETCH_ERROR)],
    [
      always(and(onlyEmptyLineItems, !isExpiredSession)), // something has gone wrong
      always(EXIT_CHECKOUT)
    ],
    [always(payment.paymentRedirecting), always(INVOICE_PENDING)],
    [always(!!payment.complete), always(SUCCESS)],
    [always(and(payment.initialized, !payment.complete)), always(IN_PROGRESS)],
    [always(isExpiredSession), always(IN_PROGRESS)],
    [T, always(INVOICE_FETCH_ERROR)]
  ])();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [checkoutPhase]);
  const goToPage = nextPage => () =>
    subPageActions.navigateToCheckoutSubpage({
      serviceName,
      invoiceId,
      subpage: nextPage
    });

  const walletScreenTabName = walletScreenV2Enabled ? "Contact" : "Login";
  const allTabs = [
    {
      text: walletScreenTabName,
      enabled: walletEnabled && !payment.isProfilePayment
    },
    {
      text: "Payment",
      enabled: payment.amountEnabled || payment.methodEnabled
    },
    {
      text: "Billing",
      enabled: payment.customerInformationEnabled
    },
    { text: "Confirmation", enabled: payment.confirmationEnabled }
  ];
  const routeRegexs = {
    wallet: /\/wallet/,
    payment: /\/payment\/?$/,
    customerInfo: /\/customer-information/,
    confirmation: /\/confirmation/
  };
  const currentTab = cond([
    [test(routeRegexs.wallet), always(walletScreenTabName)],
    [test(routeRegexs.payment), always("Payment")],
    [test(routeRegexs.customerInfo), always("Billing")],
    [test(routeRegexs.confirmation), always("Confirmation")]
  ])(location.pathname);
  const enabledTabs = allTabs.filter(t => t.enabled).map(t => t.text);
  const currentTabIndex = enabledTabs.findIndex(t => t === currentTab);
  const stepCounterText = `Step ${currentTabIndex + 1} of ${
    enabledTabs.length
  }`;
  const mobileStepCounterText = `${currentTabIndex + 1} of ${
    enabledTabs.length
  }`;
  const stepCounter = (
    <Paragraph
      color={constants.colors.STORM_GREY}
      margin={
        currentTab === walletScreenTabName && !isMobile
          ? "1rem 0 0 0"
          : "inherit"
      }
      extraStyles={`text-align: right;`}
    >
      {stepCounterText}
    </Paragraph>
  );
  const stepCounterMobile = (
    <Paragraph
      margin="0 0 0.5rem 0"
      extraStyles="display: flex; justify-content: space-between; align-items: center;"
      weight={FONT_WEIGHT_REGULAR}
    >
      <span
        style={{
          fontWeight: FONT_WEIGHT_SEMIBOLD,
          fontSize: "1.5rem",
          lineHeight: "2.2857rem"
        }}
      >
        {enabledTabs[currentTabIndex]}
      </span>
      <span
        style={{
          color: constants.colors.STORM_GREY,
          fontSize: "1rem",
          lineHeight: "1.5rem"
        }}
      >
        Step {mobileStepCounterText}
      </span>
    </Paragraph>
  );
  const subHeader = !isMobile ? (
    <HighlightTabRow
      tabs={enabledTabs}
      highlightIndex={currentTabIndex}
      isMobile={isMobile}
    />
  ) : (
    <Fragment />
  );
  const gutterPx = checkoutPhase === SUCCESS ? 0 : isMobile ? 16 : 32;
  const subClient = payment.subClientSlug || payment.serviceName;
  const subClientPaymentConfig =
    paymentConfigurations?.[subClient] ?? paymentConfigurations.default;
  const agencyDisplayName =
    subClientPaymentConfig?.options?.agencyDisplayName ?? formatName(subClient);
  const childProps = {
    actions,
    goToPage,
    payment,
    subPageActions,
    forms,
    clearPayment,
    isLoggedIn,
    navigate,
    savedCreditCards,
    savedBankAccounts,
    savedAddresses,
    savedContactEmails,
    savedContactPhone,
    captchaSiteKey,
    isMobile,
    supportsTouch,
    mobileWidth,
    hasPaymentFlag,
    walletEnabled,
    wallet,
    walletLoginAlertBar,
    walletVerifyAlertBar,
    walletForgotPasswordAlertBar,
    subClientPaymentConfig,
    expiredURL,
    cartEnabled,
    cartsCount,
    openCartSlider,
    isExpiredSession,
    expiredURL,
    handleFocusErrors,
    stepCounter,
    stepCounterMobile,
    paymentAmountIsLoading,
    paymentDetailsIsLoading,
    multiCartEnabled,
    agencyDisplayName,
    profileResources,
    walletScreenV2Enabled,
    usesCustomerInformationEmail,
    useCustomerInformationEmail,
    currentTabIndex
  };

  const paymentDetailsPage = withCommonSubpageArgs(
    PaymentDetails,
    childProps
  )({
    lineItems: payment.paymentDetailsLineItems,
    isLoading: visitIdLoading || paymentDetailsIsLoading,
    isError: visitIdRequestFailure
  });

  const isPayable = !payment.isInvoice || isInvoicePending(payment);

  const paymentDetailsContent = isPayable ? (
    <Stack childGap="24px">
      <Box padding={`${isMobile ? "1rem" : "1.5rem"} 0 0`}>
        {paymentDetailsPage}
      </Box>
      {showTestSiteAlert(window.location.host) && (
        <Alert
          heading="Test site"
          text="For testing purposes only - You will not be charged"
          variant="warn"
          extraStyles="min-height: auto;"
          innerContentPadding="12px"
          padding="4px"
          showQuitLink={false}
        />
      )}
    </Stack>
  ) : (
    <InvalidInvoiceStatusModal payment={payment} />
  );

  const childRouteMap = {
    [CHECKOUT_WALLET]: walletEnabled && !payment.isProfilePayment && isPayable,
    [CHECKOUT_PAYMENT]: payment.amountEnabled || payment.methodEnabled,
    [CHECKOUT_INFO]: payment.customerInformationEnabled,
    [CHECKOUT_CONFIRM]: payment.confirmationEnabled,
    [CHECKOUT_EXPIRED_SESSION]: true
  };

  const filteredRoutes = [
    CHECKOUT_WALLET,
    CHECKOUT_PAYMENT,
    CHECKOUT_INFO,
    CHECKOUT_CONFIRM,
    CHECKOUT_EXPIRED_SESSION
  ].filter(routeConstant => childRouteMap[routeConstant]);

  const subRoutes = [
    ...buildRoutes(filteredRoutes, routes, childProps),
    {
      path: "*",
      redirectTo: checkoutLandingPage({
        walletEnabled,
        isProfilePayment: payment.isProfilePayment
      })
    }
  ];

  const success = withCommonSubpageArgs(
    PaymentSuccess,
    childProps
  )({ navigateToProfile });

  const progress = withCommonSubpageArgs(
    PaymentProgress,
    childProps
  )({
    subHeader,
    gutterPx,
    subRoutes,
    paymentDetailsContent,
    alerts
  });

  const exitCheckout = () => {
    if (test(URL_TEST, returnDest)) {
      window.location = returnDest;
    } else {
      return <Navigate to={returnDest} replace />;
    }
  };

  const subpageContent = {
    [INVOICE_PENDING]: () => (
      <div style={{ flex: 1 }}>
        <Loading />
      </div>
    ),
    [INVOICE_FETCH_ERROR]: () => (
      <WholeBodyErrorWrapper>
        <AlertBar {...alerts} />
      </WholeBodyErrorWrapper>
    ),
    [EXIT_CHECKOUT]: exitCheckout,
    [IN_PROGRESS]: () => progress,
    [SUCCESS]: () => success
  }[checkoutPhase];

  return <Fragment>{subpageContent()}</Fragment>;
};

export default withWindowSize(React.memo(Payment));
