import React, { useEffect, useState } from "react";
import Checkbox from "../../atoms/checkbox";
import {
  required,
  matchesField,
  hasLength,
  isRoutingNumber
} from "redux-freeform";
import FormSelect from "../../atoms/form-select";
import {
  FormInput,
  FormInputColumn,
  FormContainer
} from "../../atoms/form-layouts";
import AccountAndRoutingModal from "../account-and-routing-modal";
import { noop } from "../../../util/general";
import { Cluster, Cover } from "../../atoms/layouts";
import TermsAndConditions from "../terms-and-conditions";

const PaymentFormACH = ({
  variant = "default",
  defaultMethod,
  toggleCheckbox,
  hideDefaultPayment = true,
  allowBankAccountType,
  clearOnDismount,
  fields,
  actions,
  showErrors,
  handleSubmit = noop,
  showWalletCheckbox,
  saveToWallet,
  walletCheckboxMarked,
  termsContent
}) => {
  if (clearOnDismount) {
    useEffect(() => () => actions.form.clear(), []);
  }
  const [showRouting, toggleShowRouting] = useState(false);
  const [showAccount, toggleShowAccount] = useState(false);
  const showTerms = !!termsContent;

  const nameErrors = {
    [required.error]: "Name is required"
  };
  const routingNumberErrors = {
    [required.error]: "Routing number is required",
    [hasLength.error]: "Routing number must be 9 digits",
    [isRoutingNumber.error]: "Invalid routing number"
  };
  const confirmRoutingNumberErrors = {
    [matchesField.error]:
      "Confirm routing number field must match routing number"
  };
  const accountNumberErrors = {
    [required.error]: "Account number is required",
    [hasLength.error]: "Account number must be between 5 and 17 digits"
  };
  const confirmAccountNumberErrors = {
    [matchesField.error]:
      "Confirm account number field must match account number"
  };
  const accountTypeErrors = {
    [required.error]: "Account type is required"
  };

  return (
    <FormContainer variant={variant} role="form" aria-label="ACH Payment">
      <FormInputColumn>
        <FormInput
          labelTextWhenNoError="Name on checking account"
          dataQa="Name on checking account"
          errorMessages={nameErrors}
          field={fields.name}
          fieldActions={actions.fields.name}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="name"
          isRequired={true}
        />
        <FormInput
          labelTextWhenNoError="Routing number"
          dataQa="Routing number"
          errorMessages={routingNumberErrors}
          field={fields.routingNumber}
          fieldActions={actions.fields.routingNumber}
          showErrors={showErrors}
          isNum
          helperModal={() => (
            <AccountAndRoutingModal
              link="What is this?"
              title="Where is my routing number?"
              content="Your routing number is the 9-digit number in the bottom left corner of your check."
              imageType="Routing"
              isOpen={showRouting}
              toggleOpen={toggleShowRouting}
              data-qa="What is my routing number"
            />
          )}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          isRequired={true}
        />
        <FormInput
          labelTextWhenNoError="Confirm routing number"
          dataQa="Confirm routing number"
          errorMessages={confirmRoutingNumberErrors}
          field={fields.confirmRoutingNumber}
          fieldActions={actions.fields.confirmRoutingNumber}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          isNum
          isRequired={true}
        />
        <FormInput
          labelTextWhenNoError="Account number"
          dataQa="Account number"
          errorMessages={accountNumberErrors}
          field={fields.accountNumber}
          fieldActions={actions.fields.accountNumber}
          showErrors={showErrors}
          isNum
          helperModal={() => (
            <AccountAndRoutingModal
              link="What is this?"
              title="Where is my account number?"
              content="Your account number is usually the 10-digit number in the bottom of your check to the right of the routing number."
              imageType="Account"
              isOpen={showAccount}
              toggleOpen={toggleShowAccount}
              dataQa="What is my account number"
            />
          )}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          isRequired={true}
        />
        <FormInput
          labelTextWhenNoError="Confirm account number"
          dataQa="Confirm account number"
          errorMessages={confirmAccountNumberErrors}
          field={fields.confirmAccountNumber}
          fieldActions={actions.fields.confirmAccountNumber}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          isRequired={true}
          isNum
        />
        {allowBankAccountType && (
          <FormSelect
            labelTextWhenNoError="Account type"
            dataQa="Account type"
            options={[
              { text: "Select account type", value: "" },
              { text: "Checking", value: "CHECKING" },
              { text: "Savings", value: "SAVINGS" }
            ]}
            fieldActions={actions.fields.accountType}
            showErrors={showErrors}
            errorMessages={accountTypeErrors}
            field={fields.accountType}
            isRequired={true}
          />
        )}
        {!hideDefaultPayment && (
          <Checkbox
            title="Save as Default Payment Method"
            dataQa="default-payment-ach"
            name="default-payment-ach"
            onChange={toggleCheckbox}
            checked={defaultMethod.value}
            hidden={hideDefaultPayment}
          />
        )}
        {(showWalletCheckbox || showTerms) && (
          <Cluster childGap={"4px"} align="center">
            {showWalletCheckbox && (
              <Checkbox
                name="bank checkbox"
                dataQa="Save checking account to wallet"
                title="Save checking account to wallet."
                checked={walletCheckboxMarked}
                onChange={saveToWallet}
              />
            )}
            {showTerms && (
              <Cover singleChild>
                <TermsAndConditions
                  version="v2"
                  showCheckbox={false}
                  description="View"
                  terms={termsContent}
                  initialFocusSelector={".modal-close-button"}
                />
              </Cover>
            )}
          </Cluster>
        )}
      </FormInputColumn>
    </FormContainer>
  );
};

export default PaymentFormACH;
