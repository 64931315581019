import { init } from "../../../../util/Nested";
import * as Nested from "../../../../util/Nested";
import { LOGOUT, CLEAR_STATE } from "../../../../state/reducer";
import * as AlertBarState from "../../../../components/alert-bar/AlertBar.state";
import {
  computeTotal,
  validateAndAddItems,
  removeItem,
  removeToast
} from "./ShoppingCart.selectors";
import {
  getAllCarts,
  getMultiCartItemsCount,
  getMultiCartTotal,
  getPaymentAgencyDisplayName
} from "./MultiCart.selectors";
import { getCartId, getPaymentComplete } from "../payment/Payment.selectors";
import { formatName, noop } from "../../../../util/general";

const initialCart = {
  basicItems: [],
  standardItems: [],
  items: [],
  config: {
    payment: null
  },
  total: 0,
  toasts: []
};

const initialState = {
  carts: {},
  ...initialCart,
  cartSliderOpen: false,
  displayMultiCartInstructionalAlert: true,
  focusArea: null,
  screenReaderMessages: []
};

export const CONFIGURE_SHOPPING_CART = "cart/CONFIGURE_SHOPPING_CART";
export const configureShoppingCart = ({ config }) => ({
  type: CONFIGURE_SHOPPING_CART,
  payload: {
    config
  }
});

export const POPULATE_CART_PAYMENT_CONFIG = "cart/POPULATE_CART_PAYMENT_CONFIG";
export const populateCartPaymentConfig = paymentConfig => ({
  type: POPULATE_CART_PAYMENT_CONFIG,
  payload: {
    paymentConfig
  }
});

export const ADD_TO_SHOPPING_CART = "cart/ADD_TO_SHOPPING_CART";
export const addToShoppingCart = (items, itemType) => ({
  type: ADD_TO_SHOPPING_CART,
  payload: {
    items,
    itemType
  }
});

export const REMOVE_FROM_SHOPPING_CART = "cart/REMOVE_FROM_SHOPPING_CART";
export const removeFromShoppingCart = item => ({
  type: REMOVE_FROM_SHOPPING_CART,
  payload: {
    item
  }
});

export const CLEAR_SHOPPING_CART = "cart/CLEAR_SHOPPING_CART";
export const clearShoppingCart = () => ({
  type: CLEAR_SHOPPING_CART
});

export const OPEN_CART_SLIDER = "cart/OPEN_CART_SLIDER";
export const openCartSlider = () => ({
  type: OPEN_CART_SLIDER
});

export const CLOSE_CART_SLIDER = "cart/CLOSE_CART_SLIDER";
export const closeCartSlider = () => ({
  type: CLOSE_CART_SLIDER
});

export const CHECKOUT_FROM_CART = "cart/CHECKOUT_FROM_CART";
export const checkoutFromCart = () => ({
  type: CHECKOUT_FROM_CART
});

export const SET_CART_FROM_LOCAL_STORAGE = "cart/SET_CART_FROM_LOCAL_STORAGE";
export const setCartFromLocalStorage = storedCartPayload => ({
  type: SET_CART_FROM_LOCAL_STORAGE,
  payload: storedCartPayload
});

export const CONFIGURE_MULTI_CART = "cart/CONFIGURE_MULTI_CART";
export const configureMultiCart = ({ cartId, config }) => ({
  type: CONFIGURE_MULTI_CART,
  payload: {
    cartId,
    config
  }
});

export const POPULATE_MULTI_CART_PAYMENT_CONFIG =
  "cart/POPULATE_MULTI_CART_PAYMENT_CONFIG";
export const populateMultiCartPaymentConfig = ({ cartId, paymentConfig }) => ({
  type: POPULATE_MULTI_CART_PAYMENT_CONFIG,
  payload: {
    cartId,
    paymentConfig
  }
});

export const ADD_TO_MULTI_CART = "cart/ADD_TO_MULTI_CART";
export const addToMultiCart = ({ cartId, items, itemType }) => ({
  type: ADD_TO_MULTI_CART,
  payload: {
    cartId,
    items,
    itemType
  }
});

export const REMOVE_FROM_MULTI_CART = "cart/REMOVE_FROM_MULTI_CART";
export const removeFromMultiCart = ({ cartId, item }) => ({
  type: REMOVE_FROM_MULTI_CART,
  payload: {
    cartId,
    item
  }
});

export const CLEAR_MULTI_CART = "cart/CLEAR_MULTI_CART";
export const clearMultiCart = ({ cartId }) => ({
  type: CLEAR_MULTI_CART,
  payload: {
    cartId
  }
});

export const CHECKOUT_FROM_MULTI_CART = "cart/CHECKOUT_FROM_MULTI_CART";
export const checkoutFromMultiCart = ({ cartId }) => ({
  type: CHECKOUT_FROM_MULTI_CART,
  payload: {
    cartId
  }
});

export const REMOVE_TOAST_FROM_MULTI_CART = "cart/REMOVE_TOAST_FROM_MULTI_CART";
export const removeToastFromMultiCart = ({ cartId, id }) => ({
  type: REMOVE_TOAST_FROM_MULTI_CART,
  payload: {
    cartId,
    id
  }
});

export const HIDE_MULTI_CART_INSTRUCTIONAL_ALERT =
  "cart/HIDE_MULTI_CART_INSTRUCTIONAL_ALERT";
export const hideMultiCartInstructionalAlert = () => ({
  type: HIDE_MULTI_CART_INSTRUCTIONAL_ALERT
});

export const ADD_SCREEN_READER_MESSAGE = "cart/ADD_SCREEN_READER_MESSAGE";
export const addScreenReaderMessage = ({ message }) => ({
  type: ADD_SCREEN_READER_MESSAGE,
  payload: {
    message
  }
});

export const CLEAR_SCREEN_READER_MESSAGE = "cart/CLEAR_SCREEN_READER_MESSAGE";
export const clearScreenReaderMessage = () => ({
  type: CLEAR_SCREEN_READER_MESSAGE
});

export const HANDLE_MULTI_CART_FOCUS = "cart/HANDLE_MULTI_CART_FOCUS";
export const handleMultiCartFocus = ({ focusArea }) => ({
  type: HANDLE_MULTI_CART_FOCUS,
  payload: {
    focusArea
  }
});

export const OPEN_MULTI_CART_MODAL = "cart/OPEN_MULTI_CART_MODAL";
export const openMultiCartModal = () => ({
  type: OPEN_MULTI_CART_MODAL
});

export const CLOSE_MULTI_CART_MODAL = "cart/CLOSE_MULTI_CART_MODAL";
export const closeMultiCartModal = () => ({
  type: CLOSE_MULTI_CART_MODAL
});

export const SET_MULTI_CART_MODAL_CONTENT = "cart/SET_MULTI_CART_MODAL_CONTENT";
export const setMultiCartModalContent = ({
  modalHeading,
  modalBody,
  modalContinueAction
}) => ({
  type: SET_MULTI_CART_MODAL_CONTENT,
  payload: {
    modalHeading,
    modalBody,
    modalContinueAction
  }
});

export const CLEAR_MULTI_CART_MODAL_CONTENT =
  "cart/CLEAR_MULTI_CART_MODAL_CONTENT";
export const clearMultiCartModalContent = () => ({
  type: CLEAR_MULTI_CART_MODAL_CONTENT
});

const ALERT_BAR_ACTION = "cart/ALERT_BAR_ACTION";

const _reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONFIGURE_SHOPPING_CART: {
      return {
        ...state,
        config: {
          ...state.config,
          ...payload.config
        }
      };
    }
    case POPULATE_CART_PAYMENT_CONFIG: {
      return {
        ...state,
        config: {
          ...state.config,
          payment: payload.paymentConfig
        }
      };
    }
    case ADD_TO_SHOPPING_CART: {
      const newItemsCollection = validateAndAddItems(
        state,
        payload.items,
        payload.itemType
      );
      return {
        ...state,
        ...newItemsCollection,
        total: computeTotal(newItemsCollection.items)
      };
    }
    case REMOVE_FROM_SHOPPING_CART: {
      const updatedItemsCollection = removeItem(state, payload.item);

      return {
        ...state,
        ...updatedItemsCollection,
        total: computeTotal(updatedItemsCollection.items)
      };
    }
    case CLEAR_SHOPPING_CART: {
      return {
        ...state,
        config: {
          ...state.config,
          payment: null
        },
        basicItems: [],
        standardItems: [],
        items: [],
        total: 0
      };
    }
    case OPEN_CART_SLIDER: {
      return {
        ...state,
        cartSliderOpen: true
      };
    }
    case CLOSE_CART_SLIDER: {
      return {
        ...state,
        cartSliderOpen: false,
        focusArea: null,
        screenReaderMessages: []
      };
    }
    case SET_CART_FROM_LOCAL_STORAGE: {
      return {
        ...state,
        ...payload
      };
    }
    case CONFIGURE_MULTI_CART: {
      const cartId = payload.cartId;
      const existingCart = state.carts[cartId];
      const displayName = formatName(cartId);

      return {
        ...state,
        carts: {
          ...state.carts,
          [cartId]: {
            ...initialCart,
            ...existingCart,
            id: cartId,
            displayName,
            config: {
              ...initialCart?.config,
              ...existingCart?.config,
              ...payload.config
            }
          }
        }
      };
    }
    case POPULATE_MULTI_CART_PAYMENT_CONFIG: {
      const cartId = payload.cartId;
      const existingCart = state.carts[cartId];
      const displayName =
        getPaymentAgencyDisplayName(payload.paymentConfig) ??
        existingCart.displayName;
      return {
        ...state,
        carts: {
          ...state.carts,
          [cartId]: {
            ...initialCart,
            ...existingCart,
            displayName,
            config: {
              ...initialCart?.config,
              ...existingCart.config,
              payment: payload.paymentConfig
            }
          }
        }
      };
    }
    case ADD_TO_MULTI_CART: {
      const cartId = payload.cartId;
      const existingCart = state.carts[cartId];
      const newItemsCollection = validateAndAddItems(
        existingCart,
        payload.items,
        payload.itemType
      );

      return {
        ...state,
        carts: {
          ...state.carts,
          [cartId]: {
            ...initialCart,
            ...existingCart,
            ...newItemsCollection,
            total: computeTotal(newItemsCollection.items),
            toasts: [...existingCart.toasts, ...newItemsCollection.toasts]
          }
        },
        focusArea: null
      };
    }
    case REMOVE_FROM_MULTI_CART: {
      const cartId = payload.cartId;
      const existingCart = state.carts[cartId];
      const updatedItemsCollection = removeItem(existingCart, payload.item);
      // If a cart has no more items in it, completely remove it.
      if (updatedItemsCollection.items.length === 0) {
        let carts = { ...state.carts };
        delete carts[cartId];
        return {
          ...state,
          carts
        };
      }

      return {
        ...state,
        carts: {
          ...state.carts,
          [cartId]: {
            ...initialCart,
            ...existingCart,
            ...updatedItemsCollection,
            total: computeTotal(updatedItemsCollection.items)
          }
        }
      };
    }
    case REMOVE_TOAST_FROM_MULTI_CART: {
      const cartId = payload.cartId;
      const existingCart = state.carts[cartId];

      if (!existingCart) return state;

      const toasts = removeToast(existingCart?.toasts, payload.id);

      return {
        ...state,
        carts: {
          ...state.carts,
          [cartId]: {
            ...initialCart,
            ...existingCart,
            toasts: [...toasts]
          }
        }
      };
    }
    case OPEN_MULTI_CART_MODAL: {
      return {
        ...state,
        cartModalOpen: true
      };
    }
    case CLOSE_MULTI_CART_MODAL: {
      return {
        ...state,
        cartModalOpen: false
      };
    }
    case SET_MULTI_CART_MODAL_CONTENT: {
      return {
        ...state,
        modalContents: {
          ...state.modalContents,
          heading: payload.modalHeading,
          body: payload.modalBody,
          continueAction: payload.modalContinueAction
        }
      };
    }
    case CLEAR_MULTI_CART_MODAL_CONTENT: {
      return {
        ...state,
        modalContents: {
          ...state.modalContents,
          heading: "",
          body: "",
          continueAction: noop
        }
      };
    }
    case CLEAR_MULTI_CART: {
      const cartId = payload.cartId;
      let carts = { ...state.carts };
      delete carts[cartId];

      return {
        ...state,
        carts
      };
    }
    case ADD_SCREEN_READER_MESSAGE: {
      return {
        ...state,
        screenReaderMessages: [
          ...state.screenReaderMessages,
          {
            message: payload.message
          }
        ]
      };
    }
    case CLEAR_SCREEN_READER_MESSAGE: {
      return {
        ...state,
        screenReaderMessages: []
      };
    }
    case HANDLE_MULTI_CART_FOCUS: {
      return {
        ...state,
        focusArea: payload.focusArea
      };
    }
    case HIDE_MULTI_CART_INSTRUCTIONAL_ALERT: {
      return {
        ...state,
        displayMultiCartInstructionalAlert: false
      };
    }
    default:
      return state;
  }
};

const _mapStateToProps = state => {
  const { cart, global } = state;
  return {
    items: cart.items,
    config: cart.config,
    total: cart.total,
    cartSliderOpen: cart.cartSliderOpen,
    displayMultiCartInstructionalAlert: cart.displayMultiCartInstructionalAlert,
    cartEnabled: global?.clientMetadata?.data?.shoppingCartEnabled ?? false,
    multiCartItemsCount: getMultiCartItemsCount(state),
    multiCartTotal: getMultiCartTotal(state),
    carts: getAllCarts(state),
    checkoutCartId: getCartId(state),
    checkoutPaymentComplete: getPaymentComplete(state),
    cartModalOpen: cart.cartModalOpen,
    modalContents: cart.modalContents,
    focusArea: cart.focusArea,
    screenReaderMessages: cart.screenReaderMessages
  };
};

const _mapDispatchToProps = dispatch => {
  return {
    configureShoppingCart: config => dispatch(configureShoppingCart(config)),
    addToShoppingCart: (items, config) =>
      dispatch(addToShoppingCart(items, config)),
    removeFromShoppingCart: item => dispatch(removeFromShoppingCart(item)),
    clearShoppingCart: () => dispatch(clearShoppingCart()),
    openCartSlider: () => dispatch(openCartSlider()),
    closeCartSlider: () => dispatch(closeCartSlider()),
    checkoutFromCart: () => dispatch(checkoutFromCart()),
    setCartFromLocalStorage: storedCartPayload =>
      dispatch(setCartFromLocalStorage(storedCartPayload)),
    configureMultiCart: ({ cartId, config }) =>
      dispatch(configureMultiCart({ cartId, config })),
    addToMultiCart: ({ cartId, items, itemType }) =>
      dispatch(addToMultiCart({ cartId, items, itemType })),
    removeFromMultiCart: item => dispatch(removeFromMultiCart(item)),
    clearMultiCart: ({ cartId }) => dispatch(clearMultiCart({ cartId })),
    checkoutFromMultiCart: ({ cartId }) =>
      dispatch(checkoutFromMultiCart({ cartId })),
    removeToastFromMultiCart: ({ cartId, id }) =>
      dispatch(removeToastFromMultiCart({ cartId, id })),
    hideMultiCartInstructionalAlert: () =>
      dispatch(hideMultiCartInstructionalAlert()),
    clearScreenReaderMessage: () => dispatch(clearScreenReaderMessage()),
    openMultiCartModal: () => dispatch(openMultiCartModal()),
    closeMultiCartModal: () => dispatch(closeMultiCartModal()),
    setMultiCartModalContent: ({
      modalHeading,
      modalBody,
      modalContinueAction
    }) =>
      dispatch(
        setMultiCartModalContent({
          modalHeading,
          modalBody,
          modalContinueAction
        })
      ),
    clearMultiCartModalContent: () => dispatch(clearMultiCartModalContent())
  };
};

const {
  reducer: nestedReducer,
  mapStateToProps,
  mapDispatchToProps,
  actions
} = Nested.nestStates(
  {
    reducer: _reducer,
    mapStateToProps: _mapStateToProps,
    mapDispatchToProps: _mapDispatchToProps
  },
  {
    alerts: {
      ...AlertBarState,
      actionType: ALERT_BAR_ACTION
    }
  },
  "cart"
);

const reducer = (state, action) =>
  action.type === LOGOUT || action.type === CLEAR_STATE
    ? init(nestedReducer)
    : nestedReducer(state, action);

export { reducer, mapStateToProps, mapDispatchToProps, actions };
