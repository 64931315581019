import { coerceToLowercase, coerceToUppercase } from "./general";
import {
  NEW_ACH,
  NEW_CC,
  SAVED_ACH,
  SAVED_CC
} from "../apps/checkout/pages/payment/Payment.state";

const INVOICE_EXPIRED = "EXPIRED";
const INVOICE_PAID = "PAID";
const INVOICE_PENDING = "PENDING";

export const calculatePaymentMaximumInCentsForPaymentType = ({
  configForPaymentTypes,
  globalPaymentMaximumInCents,
  selectedPaymentMethodType
}) => {
  const CREDIT_CARD = "creditCard";
  const BANK_ACCOUNT = "ach";
  const paymentTypeForSelectedPaymentMethod = {
    [SAVED_CC]: CREDIT_CARD,
    [SAVED_ACH]: BANK_ACCOUNT,
    [NEW_CC]: CREDIT_CARD,
    [NEW_ACH]: BANK_ACCOUNT
  };
  const paymentType =
    paymentTypeForSelectedPaymentMethod[selectedPaymentMethodType];

  const paymentMaximumInCentsForPaymentType =
    configForPaymentTypes?.[paymentType]?.paymentMaximumInCents;

  if (paymentMaximumInCentsForPaymentType) {
    return paymentMaximumInCentsForPaymentType;
  } else {
    return globalPaymentMaximumInCents;
  }
};

/**
 * Searches a payment object's custom attributes to find a matching search key.
 * @param {object} payment
 * @param {[object]} payment.customAttributes
 * @param {string} searchKey
 * @returns {*} the value of a custom attribute matching the provided search key
 */
export const getCustomAttribute = ({ customAttributes = [] }, searchKey = "") =>
  customAttributes.find(({ key }) => key === searchKey)?.value;

/**
 * Checks a payment object to see if it contains an expired invoice.
 * @param {object} payment
 * @param {string} payment.status
 * @returns whether the invoice status is expired
 */
export const isInvoiceExpired = ({ status = "" }) =>
  coerceToUppercase(status) === INVOICE_EXPIRED;

/**
 * Checks a payment object to see if it contains a paid invoice.
 * @param {object} payment
 * @param {string} payment.status
 * @returns whether the invoice status is paid
 */
export const isInvoicePaid = ({ status = "" }) =>
  coerceToUppercase(status) === INVOICE_PAID;

/**
 * Checks a payment object to see if it contains an unpaid invoice.
 * @param {object} payment
 * @param {string} payment.status
 * @returns whether the invoice status is unpaid
 */
export const isInvoicePending = ({ status = "" }) =>
  coerceToUppercase(status) === INVOICE_PENDING;

/**
 * Checks a payment object to see if it contains a PayLink invoice.
 * @param {object} payment
 * @param {string} payment.routingKey
 * @returns whether the invoice is from PayLink
 */
export const isInvoicePaylink = ({ routingKey = "" }) =>
  /paylink/.test(coerceToLowercase(routingKey));
