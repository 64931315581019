import React, { useEffect, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import {
  required,
  hasLength,
  matchesRegex,
  dateAfterToday,
  isValidMonth
} from "redux-freeform";
import Checkbox from "../../atoms/checkbox";
import CountryDropdown from "../../atoms/country-dropdown";
import {
  checkCardBrand,
  noop,
  checkDeniedCards,
  displayCardBrand
} from "../../../util/general";
import {
  expirationDateFormat,
  creditCardFormat,
  zipFormat
} from "../../../util/formats";
import {
  FormInput,
  FormInputColumn,
  FormContainer,
  FormInputRow
} from "../../atoms/form-layouts";
import { Box, Cluster, Cover } from "../../atoms/layouts";
import withWindowSize from "../../withWindowSize";
import TermsAndConditions from "../terms-and-conditions";

const PaymentFormCard = ({
  variant = "default",
  hideZipCode = false,
  clearOnDismount,
  fields,
  actions,
  showErrors,
  handleSubmit = noop,
  showWalletCheckbox,
  saveToWallet,
  walletCheckboxMarked,
  deniedCards,
  termsContent
}) => {
  const { isMobile } = useContext(ThemeContext);
  const showTerms = !!termsContent;

  useEffect(() => {
    if (deniedCards) {
      deniedCards.map(card =>
        actions.fields.creditCardNumber.addValidator(
          matchesRegex(checkDeniedCards(card))
        )
      );
    }
  }, []);

  useEffect(() => {
    if (clearOnDismount) {
      return () => actions.form.clear();
    }
  }, []);

  const nameOnCardErrors = {
    [required.error]: "Name is required"
  };
  const creditCardNumberErrors = {
    [required.error]: "Credit card number is required",
    [hasLength.error]: "Credit card number is invalid",
    [matchesRegex.error]: `${displayCardBrand(
      fields.creditCardNumber.rawValue
    )} cards are not available for payment`
  };
  const expirationDateErrors = {
    [required.error]: "Expiration date is required",
    [hasLength.error]: "Expiration date is invalid",
    [isValidMonth.error]: "Expiration month is invalid",
    [dateAfterToday.error]: "Expiration date is invalid"
  };
  const cvvErrors = {
    [required.error]: "CVV is required",
    [hasLength.error]: "CVV is invalid"
  };
  const zipCodeErrors = {
    [required.error]: "Zip code is required",
    [hasLength.error]: "Zip code is invalid"
  };
  const countryErrorMessages = {
    [required.error]: "Country is required"
  };

  const isUS = fields.country.rawValue === "US";
  return (
    <FormContainer variant={variant} role="form" aria-label="Card payment">
      <FormInputColumn>
        {!hideZipCode && (
          <CountryDropdown
            labelTextWhenNoError="Country"
            errorMessages={countryErrorMessages}
            field={fields.country}
            onChange={value => {
              actions.fields.country.set(value);
              // temporary measure to not dirty fields until
              // we can write a reset function for fields
              if (fields.zipCode.rawValue) {
                actions.fields.zipCode.set("");
              }
            }}
            showErrors={showErrors}
            dataQa="Country"
          />
        )}
        <FormInput
          labelTextWhenNoError="Name on card"
          dataQa="Name on card"
          errorMessages={nameOnCardErrors}
          field={fields.nameOnCard}
          fieldActions={actions.fields.nameOnCard}
          showErrors={showErrors}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autocompleteValue="cc-name"
          isRequired={true}
        />
        <FormInput
          labelTextWhenNoError="Credit card number"
          dataQa="Credit card number"
          errorMessages={creditCardNumberErrors}
          field={fields.creditCardNumber}
          fieldActions={actions.fields.creditCardNumber}
          showErrors={showErrors}
          formatter={creditCardFormat}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          isNum
          autocompleteValue="cc-number"
          isRequired={true}
        />
        <FormInputRow
          breakpoint={isMobile ? "1000rem" : "21rem"}
          childGap={isMobile ? "0rem" : "1rem"}
        >
          <FormInput
            labelTextWhenNoError="Expiration date (MM/YY)"
            dataQa="Expiration date (MM/YY)"
            errorMessages={expirationDateErrors}
            field={fields.expirationDate}
            fieldActions={actions.fields.expirationDate}
            showErrors={showErrors}
            formatter={expirationDateFormat}
            onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
            isNum
            removeFromValue={/\//} // removes "/" from browser autofill
            autocompleteValue="cc-exp"
            isRequired={true}
          />
          <FormInput
            labelTextWhenNoError="CVV"
            dataQa="CVV"
            errorMessages={cvvErrors}
            field={fields.cvv}
            fieldActions={actions.fields.cvv}
            showErrors={showErrors}
            isNum
            background={
              checkCardBrand(fields.creditCardNumber.rawValue) == "AMEX"
                ? "/AmexCVVHint.svg"
                : "/CVVHint.svg"
            }
            onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
            autocompleteValue="cc-csc"
            isRequired={true}
          />
        </FormInputRow>
        {!hideZipCode && (
          <Box
            padding={isMobile ? "0" : "0 0.5rem 0 0"}
            width={isMobile ? "100%" : "50%"}
          >
            <FormInput
              isNum={isUS}
              formatter={isUS ? zipFormat : null}
              labelTextWhenNoError="Zip code"
              dataQa="Zip code"
              errorMessages={zipCodeErrors}
              field={fields.zipCode}
              fieldActions={actions.fields.zipCode}
              showErrors={showErrors}
              onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
              autocompleteValue="billing postal-code"
              isRequired={true}
            />
          </Box>
        )}
        {(showWalletCheckbox || showTerms) && (
          <Cluster childGap={"4px"} align="center">
            {showWalletCheckbox && (
              <Checkbox
                name="credit card checkbox"
                dataQa="Save credit card to wallet"
                title="Save credit card to wallet."
                checked={walletCheckboxMarked}
                onChange={saveToWallet}
              />
            )}
            {showTerms && (
              <Cover singleChild>
                <TermsAndConditions
                  version="v2"
                  showCheckbox={false}
                  description="View"
                  terms={termsContent}
                  initialFocusSelector={".modal-close-button"}
                />
              </Cover>
            )}
          </Cluster>
        )}
      </FormInputColumn>
    </FormContainer>
  );
};

export default withWindowSize(PaymentFormCard);
