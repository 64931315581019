import React, { useContext, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { themeComponent } from "/util/themeUtils";
import { fallbackValues } from "./ShoppingCart.theme";
import { STANDARD_ITEM_TYPE } from "./ShoppingCart.selectors";
import {
  Box,
  DefaultPageTemplate,
  Cover,
  Center,
  Switcher,
  Cluster,
  Stack,
  Motion,
  ButtonWithAction,
  Title,
  Detail,
  Paragraph,
  Text,
  Placeholder,
  EmptyCartIcon,
  CollapsibleSection,
  ObligationIcons,
  IconQuitLarge,
  TrashIcon,
  ShoppingCartIcon,
  withWindowSize,
  constants,
  util
} from "@thecb/components";
import {
  FONT_WEIGHT_SEMIBOLD,
  FONT_WEIGHT_REGULAR
} from "../../../../constants/style_constants";

export const CartStatus = ({
  total,
  itemsCount = 0,
  openCart,
  onCartScreen = false
}) => (
  <Box padding="0">
    <Cluster
      justify="space-between"
      align="center"
      childGap="16px"
      extraStyles={`padding: 0.5rem;`}
    >
      <Box padding="0">
        <Paragraph
          variant="pL"
          weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
          color={constants.colors.WHITE}
          data-qa="shopping-cart-subtotal"
        >
          {util.general.displayCurrency(total)}
        </Paragraph>
      </Box>
      <ButtonWithAction
        variant="smallGhost"
        contentOverride
        disabled={onCartScreen}
        action={openCart}
        extraStyles={`min-width: auto; min-height: auto; &:disabled { background-color: transparent; }`}
      >
        <Box padding="0">
          <ShoppingCartIcon />
          {itemsCount > 0 && (
            <Box
              padding="0"
              minHeight="15px"
              minWidth="15px"
              borderRadius="50%"
              background="#d11053"
              extraStyles={`position: absolute; top: 0; right: 0; padding: 2px; box-sizing: content-box;`}
            >
              <Text
                weight={constants.fontWeights.FONT_WEIGHT_BOLD}
                color={constants.colors.WHITE}
                fontSize="11px"
                extraStyles={`line-height: 12px;`}
                data-qa="shopping-cart-item-count"
              >
                {itemsCount >= 100 ? "+" : itemsCount}
              </Text>
            </Box>
          )}
        </Box>
      </ButtonWithAction>
    </Cluster>
  </Box>
);

const ShoppingCart = ({
  items,
  config,
  total,
  inSlider = false,
  themeValues,
  closeCartSlider,
  openCartSlider,
  removeFromShoppingCart,
  checkoutFromCart
}) => {
  const { isMobile, supportsTouch } = useContext(ThemeContext);
  const { displayCurrency } = util.general;
  const navigate = useNavigate();

  const CartHeader = ({ themeValues }) => (
    <Box padding="16px" background={themeValues.headerBackgroundColor}>
      <Center maxWidth="1224px">
        <Cluster justify="space-between" align="center">
          <Title
            as="h1"
            color={constants.colors.WHITE}
            weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
            extraStyles={`font-size: 1.375rem;`}
          >
            Cart
          </Title>
          <CartStatus
            total={total}
            itemsCount={items.length}
            openCart={openCartSlider}
            onCartScreen
          />
        </Cluster>
      </Center>
    </Box>
  );

  const CartEmpty = () => (
    <Box
      minHeight={isMobile || inSlider ? "auto" : "505px"}
      padding={isMobile || inSlider ? "40px 16px 16px" : "24px"}
      minWidth={isMobile || inSlider ? "100%" : "784px"}
      background={constants.colors.WHITE}
      boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
0px 3px 8px 0px rgb(202, 206, 216)"
      borderRadius="4px"
    >
      <Cover singleChild fillCenter>
        <Center maxWidth="100%" intrinsic>
          <Box
            extraStyles={`display: flex; flex-direction: column; justify-content: center; align-items: center; flex-grow: 1;`}
            padding="0"
          >
            <Box
              padding="0"
              extraStyles={isMobile && `svg { width: 100%; height: auto;}`}
            >
              <EmptyCartIcon />
            </Box>
            <Box
              padding="0"
              extraStyles={
                isMobile ? `margin: 32px 0;` : `margin: 32px 0 12px 0`
              }
            >
              <Title
                as="h3"
                extraStyles={`font-size: 1.75rem;`}
                weight={constants.fontWeights.FONT_WEIGHT_BOLD}
                textAlign="center"
              >
                No Items in Cart
              </Title>
              <Paragraph variant="pL" extraStyles={`text-align: center;`}>
                Please add items below.
              </Paragraph>
            </Box>
            <Box padding="0" minWidth={isMobile ? "100%" : "auto"}>
              <ButtonWithAction
                text={isMobile ? "Search for Items" : "Add Items"}
                action={() => {
                  closeCartSlider();
                  navigate(config.addMoreItemsURL);
                }}
                extraStyles={isMobile && `width: 100%; margin: 0;`}
              />
            </Box>
          </Box>
        </Center>
      </Cover>
    </Box>
  );

  const CartItem = ({
    item,
    index,
    itemSectionList,
    handleCollapsibleSection
  }) => {
    const {
      description = "",
      subDescription = "",
      identifier = "",
      amount,
      icon,
      customAttributes,
      userInputText
    } = item;

    /*
      Basic Items and Standard Items use different content for generating
      the details that appear within the collapsed section content
    */

    const generateStandardDetailsList = () =>
      isMobile ? (
        <Stack childGap="8px">
          {config?.collectionDetailsList?.map(detailsItem => (
            <Box
              padding="0"
              key={`item-${index}-details-${detailsItem.attribute}`}
            >
              <Paragraph
                variant="pS"
                weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
              >
                {detailsItem.label}
              </Paragraph>
              <Paragraph>
                {
                  customAttributes.find(
                    attr => attr.key === detailsItem.attribute
                  ).value
                }
              </Paragraph>
            </Box>
          ))}
        </Stack>
      ) : (
        <Fragment>
          <Stack childGap="8px">
            {config?.collectionDetailsList?.map(detailsItem => (
              <Box
                padding="0"
                key={`item-${index}-details-${detailsItem.label}`}
              >
                <Paragraph
                  variant="pS"
                  weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                >
                  {detailsItem.label}
                </Paragraph>
              </Box>
            ))}
          </Stack>
          <Stack childGap="8px">
            {config?.collectionDetailsList?.map(detailsItem => {
              const attrValue = customAttributes.find(
                attr => attr.key === detailsItem.attribute
              )?.value;
              return (
                <Box
                  padding="0"
                  key={`item-${index}-details-${detailsItem.attribute}`}
                >
                  <Paragraph variant="pS">{attrValue}</Paragraph>
                </Box>
              );
            })}
          </Stack>
        </Fragment>
      );

    const generateBasicDetailslist = () =>
      userInputText.map((inputItem, inputIndex) => (
        <Box
          padding="8px 0"
          key={`item-${index}-user-input-${inputIndex}`}
          extraStyles={`flex: 0 0 100%;`}
        >
          <Stack childGap="1rem">
            <Detail as="h4" variant="small" weight={FONT_WEIGHT_SEMIBOLD}>
              {`${inputItem.key}:`}
            </Detail>
            <Paragraph variant="pXS" weight={FONT_WEIGHT_REGULAR}>
              {inputItem.value}
            </Paragraph>
          </Stack>
        </Box>
      ));

    const ItemIcon =
      ObligationIcons?.[icon] ?? ObligationIcons["ACCOUNTS_GENERIC"];

    return (
      <Box
        padding="0"
        boxShadow="0px 2px 14px 0px rgb(246, 246, 249),
0px 3px 8px 0px rgb(202, 206, 216)"
        borderRadius="4px"
        extraStyles={isMobile && `margin-left: 2px; margin-right: 2px;`}
      >
        <Stack childGap="1px">
          <Box
            padding="0"
            background={constants.colors.WHITE}
            borderRadius={isMobile ? "4px 4px 0 0" : "4px"}
            minWidth={isMobile || inSlider ? "100%" : "784px"}
            key={`cart-item-${index}`}
          >
            <CollapsibleSection
              title={
                <Box
                  padding={isMobile ? "16px 0 16px 16px" : "24px 0 24px 24px"}
                  background={constants.colors.WHITE}
                >
                  <Cluster
                    justify="space-between"
                    align="center"
                    childGap="8px"
                    minWidth="100%"
                    nowrap
                  >
                    <Cluster
                      justify="space-between"
                      align="center"
                      childGap="16px"
                      nowrap
                      extraStyles={`max-width: 75%;`}
                    >
                      {item.itemType === STANDARD_ITEM_TYPE ? (
                        <Box
                          padding="0"
                          extraStyles={
                            isMobile && `margin-left: 4px!important;`
                          }
                        >
                          <ItemIcon />
                        </Box>
                      ) : (
                        <Fragment />
                      )}
                      <Box padding="0">
                        <Title
                          variant="small"
                          as="h2"
                          weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                        >
                          {description}
                        </Title>
                        <Detail variant="small">{subDescription}</Detail>
                        <Detail variant="small">{identifier}</Detail>
                      </Box>
                    </Cluster>
                    {!isMobile && (
                      <Box padding="0" extraStyles={`button { margin: 0; }`}>
                        <Title
                          as="p"
                          variant="small"
                          weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                          textAlign="right"
                        >
                          {displayCurrency(amount)}
                        </Title>
                        <ButtonWithAction
                          variant="smallGhost"
                          action={() => removeFromShoppingCart(item)}
                          contentOverride
                        >
                          <Cluster
                            childGap="8px"
                            extraStyles={`svg { width: 18px; }`}
                            nowrap
                          >
                            <TrashIcon />
                            <Text color={themeValues.linkColor} variant="pS">
                              Remove item
                            </Text>
                          </Cluster>
                        </ButtonWithAction>
                      </Box>
                    )}
                  </Cluster>
                </Box>
              }
              customTitle
              customPadding={isMobile ? "0px 16px 0 0 " : "0px 24px 0 0"}
              toggleSection={() => handleCollapsibleSection(index)}
              isOpen={itemSectionList.includes(index)}
              initiallyOpen={false}
              supportsTouch={supportsTouch}
              sectionGap="0"
              name={`${description} ${subDescription}`}
              index={index}
            >
              <Motion
                padding="0"
                transition={{ duration: 0.3 }}
                positionTransition
                extraStyles={`transform-origin: 100% 0;`}
                key={`cart-item-details-${index}`}
              >
                <Box
                  padding="0"
                  background={constants.colors.ATHENS_GREY}
                  borderRadius="0 0 4px 4px"
                >
                  <Box
                    padding={isMobile ? "16px" : "24px"}
                    borderColor={constants.colors.GHOST_GREY}
                    borderWidth="1px"
                    borderWidthOverride="1px 0 0 0"
                  >
                    <Cluster
                      justify="flex-start"
                      align="center"
                      minWidth="100%"
                      childGap={
                        item.itemType === STANDARD_ITEM_TYPE ? "24px" : "1px"
                      }
                    >
                      {item.itemType === STANDARD_ITEM_TYPE
                        ? generateStandardDetailsList()
                        : generateBasicDetailslist()}
                    </Cluster>
                  </Box>
                </Box>
              </Motion>
            </CollapsibleSection>
          </Box>
          {isMobile && (
            <Box
              padding="16px"
              minWidth="100%"
              borderColor={constants.colors.GHOST_GREY}
              background={constants.colors.ATHENS_GREY}
              borderWidth="1px"
              borderWidthOverride="1px 0 0 0"
              borderRadius="0 0 4px 4px"
            >
              <Cluster justify="space-between" align="center">
                <Box padding="0">
                  <Paragraph
                    variant="pS"
                    weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                  >
                    Amount Due
                  </Paragraph>
                  <Paragraph
                    variant="pL"
                    weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                    extraStyles={
                      isMobile && `font-size: 18px; line-height: 26px;`
                    }
                  >
                    {displayCurrency(amount)}
                  </Paragraph>
                </Box>
                <ButtonWithAction
                  variant="secondary"
                  action={() => removeFromShoppingCart(item)}
                  contentOverride
                >
                  <Cluster
                    justify="center"
                    align="center"
                    childGap="8px"
                    extraStyles={`svg { width: 18px; height: 18px; }`}
                  >
                    <TrashIcon />
                    <Text
                      color={themeValues.linkColor}
                      weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                      variant="pS"
                    >
                      Remove
                    </Text>
                  </Cluster>
                </ButtonWithAction>
              </Cluster>
            </Box>
          )}
        </Stack>
      </Box>
    );
  };

  const CartItemsList = () => {
    const [itemSectionList, setItemSectionList] = useState([]);
    const handleCollapsibleSection = index => {
      const newSectionList = itemSectionList.includes(index)
        ? itemSectionList.filter(item => item !== index)
        : [...itemSectionList, index];

      setItemSectionList(newSectionList);
    };
    const hasStandardItems = items.some(
      item => item.itemType === STANDARD_ITEM_TYPE
    );

    return (
      <Stack childGap="24px">
        {items.map((item, index) => (
          <CartItem
            key={`cart-item-${index}`}
            item={item}
            index={index}
            itemSectionList={itemSectionList}
            handleCollapsibleSection={handleCollapsibleSection}
            itemType={item.itemType}
          />
        ))}
        {/* Don't show "add more items" button for basic items */}
        {hasStandardItems && (
          <Placeholder
            action={() => {
              closeCartSlider();
              navigate(config.addMoreItemsURL);
            }}
            text="Add More to Cart"
            themeValues={themeValues}
          />
        )}
      </Stack>
    );
  };

  const CartSubtotal = () => (
    <Box
      padding={isMobile ? "24px 16px" : "24px"}
      background={constants.colors.WHITE}
      boxShadow={
        !inSlider &&
        "0px 2px 14px 0px rgb(246, 246, 249),0px 3px 8px 0px rgb(202, 206, 216)"
      }
      borderRadius="4px"
      extraStyles={`align-self: flex-start;`}
    >
      {inSlider ? (
        <Stack childGap="20px" direction={!isMobile ? "row" : "column"}>
          <Cluster
            justify="space-between"
            alignSelf={!isMobile ? "center" : "stretch"}
            flexGrow="1"
            justifySelf="stretch"
            extraStyles={isMobile && `display: flex; flex-direction: column;`}
          >
            <Stack direction="row" childGap="8px">
              <Box
                padding="0"
                extraStyles={`display: flex; align-items: center;`}
              >
                <Paragraph
                  color={constants.colors.CHARADE_GREY}
                  variant="pS"
                  weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                >
                  Items
                </Paragraph>
              </Box>
              <Box
                padding="0"
                extraStyles={`display: flex; align-items: center;`}
              >
                <Paragraph
                  color={constants.colors.CHARADE_GREY}
                  variant="pL"
                  weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                >
                  {items.length}
                </Paragraph>
              </Box>
            </Stack>
            <Detail
              as="h2"
              variant="small"
              color={constants.colors.CHARADE_GREY}
              weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
              extraStyles="display: flex; gap: 16px; align-items:center;"
            >
              <span>Subtotal</span>
              <Detail
                as="span"
                variant="large"
                color={constants.colors.CHARADE_GREY}
                weight={constants.fontWeights.FONT_WEIGHT_BOLD}
              >
                {displayCurrency(total)}
              </Detail>
            </Detail>
          </Cluster>
          <ButtonWithAction
            action={checkoutFromCart}
            text="Check out"
            disabled={items.length === 0}
            extraStyles={isMobile && `min-width: 100%; margin: 0;`}
          />
          {isMobile && (
            <ButtonWithAction
              action={closeCartSlider}
              text="Close"
              variant="secondary"
              extraStyles={`min-width: 100%; margin: 0;`}
            />
          )}
        </Stack>
      ) : (
        <Stack childGap="20px">
          <Cluster justify="space-between" align="center">
            <Paragraph color={constants.colors.CHARADE_GREY}>
              Items in cart
            </Paragraph>
            <Paragraph color={constants.colors.CHARADE_GREY}>
              {items.length}
            </Paragraph>
          </Cluster>
          <Detail
            as="h2"
            variant="regular"
            weight={constants.fontWeights.FONT_WEIGHT_BOLD}
            extraStyles="display: flex; justify-content:space-between"
          >
            <span>Subtotal</span>
            <span>{displayCurrency(total)}</span>
          </Detail>
          <ButtonWithAction
            action={checkoutFromCart}
            text="Check out"
            disabled={items.length === 0}
            extraStyles={`min-width: 100%; margin: 0;`}
          />
        </Stack>
      )}
    </Box>
  );

  const CartPageWrapper = () => (
    <DefaultPageTemplate
      header={<CartHeader themeValues={themeValues} />}
      gutters={isMobile ? "16px" : "32px"}
      content={
        <Box padding={isMobile ? "24px 0 48px" : "32px 0"} minHeight="100%">
          <Switcher
            childGap={isMobile ? "24px" : "40px"}
            breakpoint="1140px"
            constrainMobile={isMobile}
          >
            {items.length > 0 ? <CartItemsList /> : <CartEmpty />}
            <CartSubtotal />
          </Switcher>
        </Box>
      }
      maxWidth="1224px"
    />
  );

  const CartSliderWrapper = () => (
    <Box
      padding="0"
      background={constants.colors.ATHENS_GREY}
      minWidth="100%"
      minHeight="100%"
    >
      <Cover
        minHeight={isMobile ? "calc(100vh - 197px)" : "calc(100vh - 97px)"}
        fillCenter
      >
        <Box padding="24px 32px" background={themeValues.headerBackgroundColor}>
          <Cluster justify="space-between" align="center">
            <Title
              as="h2"
              color={constants.colors.WHITE}
              weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
            >
              Cart
            </Title>
            {isMobile && (
              <Box
                padding="0"
                extraStyles={`> button { min-width: auto; margin: 0; }`}
              >
                <ButtonWithAction
                  variant="smallGhost"
                  action={closeCartSlider}
                  contentOverride
                >
                  <IconQuitLarge fill={constants.colors.WHITE} />
                </ButtonWithAction>
              </Box>
            )}
          </Cluster>
        </Box>
        <Box padding="24px">
          {items.length > 0 ? <CartItemsList /> : <CartEmpty />}
        </Box>
      </Cover>
      <Box
        padding="0"
        extraStyles={`position: sticky; bottom: 0; right: 0; left: 0; z-index: 100;`}
        boxShadow={
          "5px 2px 14px 0px rgb(246, 246, 249), 5px 3px 8px 0px rgb(202, 206, 216)"
        }
      >
        <CartSubtotal />
      </Box>
    </Box>
  );

  return inSlider ? <CartSliderWrapper /> : <CartPageWrapper />;
};

export default withWindowSize(
  React.memo(themeComponent(ShoppingCart, "ShoppingCart", fallbackValues))
);
