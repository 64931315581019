import React, { useContext, useRef } from "react";
import AriaModal from "react-aria-modal";
import { ThemeContext } from "styled-components";
import { WHITE, ATHENS_GREY, SILVER_GREY } from "../../../constants/colors";
import {
  BORDER_RADIUS,
  BORDER_THIN,
  FONT_SIZE,
  FONT_WEIGHT_SEMIBOLD,
  SPACING
} from "../../../constants/style_constants";
import { noop } from "../../../util/general";
import Paragraph from "../../atoms/paragraph";
import Title from "../../atoms/title";
import { Box, Cluster } from "../../atoms/layouts";
import withWindowSize from "../../withWindowSize";
import {
  ButtonLayoutWrapper,
  CancelButton,
  CloseButton,
  CloseIconButton,
  ContinueButton
} from "./__private__";

/*
Default Modal molecule
Uses react-aria-modal behind the scenes for a11y purposes
Styling accomplished with our atoms / layout primitives

Cancel button will (for now) always use hideModal as its action
Continue button takes an action, if you want to navigate to
a different route (as with a link) connect() and use "push" from @thecb/connected-react-router
*/

const getApplicationNode = () => document.getElementById("root");

const Modal = ({
  blurUnderlay = true,
  buttonExtraStyles = "",
  cancelAction = noop,
  cancelButtonText = "Cancel",
  cancelButtonVariant = "secondary",
  children = [],
  closeButtonText = "Close",
  continueAction = noop,
  continueButtonText = "Continue",
  continueButtonVariant = "primary",
  continueURL = "",
  customWidth = "",
  dataQa = null,
  defaultWrapper = true,
  hideModal = noop,
  initialFocusSelector = "",
  isContinueActionDisabled = false,
  isLoading = false,
  maxHeight = "",
  modalBodyBg = "",
  modalBodyText = "",
  modalHeaderBg = "",
  modalHeaderText = "",
  modalOpen = false,
  noButtons = false, // for instances where modal is closed automatically
  onExit = hideModal,
  onlyCloseButton = false,
  onlyContinueButton = false,
  showCloseIconButton = false,
  underlayClickExits = true,
  useDangerButton = false
}) => {
  const { isMobile } = useContext(ThemeContext);

  // `AriaModal` uses `focus-trap` as a transient dependency. It automatically looks
  // for a tabbable node when the modal mounts. When it doesn't find one, it looks to
  // the `fallbackFocus` option. However, React does not guarantee the ref supplied to
  // this option will be populated on initial render when `focus-trap` is checking
  // these option. When there are no buttons in the modal, this causes an error.
  // Because `focus-trap` cannot be disabled, the ref itself requires a default value
  // to satisfy `focus-trap` until React populates it with the real ref value.
  //
  // See:
  //   - https://react.dev/reference/react/useRef#caveats
  //   - https://github.com/davidtheclark/react-aria-modal/pull/103
  //   - https://github.com/focus-trap/focus-trap?tab=readme-ov-file#createoptions
  const modalContainerRef = useRef("#react-aria-modal-dialog");

  const hasCloseButton = onlyCloseButton && !noButtons;
  const hasCancelButton = !onlyContinueButton && !onlyCloseButton && !noButtons;
  const hasContinueButton =
    (onlyContinueButton && !noButtons) || (!onlyCloseButton && !noButtons);

  return (
    <div ref={modalContainerRef} tabIndex="-1" data-qa={dataQa}>
      {modalOpen && (
        <AriaModal
          focusTrapOptions={{
            // fallback to resolve Jest unit test errors when tabbable doesn't exist in jsdom https://github.com/focus-trap/focus-trap-react/issues/91
            fallbackFocus: modalContainerRef?.current
          }}
          onExit={onExit}
          getApplicationNode={getApplicationNode}
          titleText={modalHeaderText}
          underlayStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(41, 42, 51, 0.45)",
            backdropFilter: blurUnderlay ? "blur(4px)" : "none",
            WebkitBackdropFilter: blurUnderlay ? "blur(4px)" : "none"
          }}
          dialogStyle={{
            borderRadius: BORDER_RADIUS.MD,
            margin: SPACING.XS,
            overflow: "auto",
            width: isMobile ? "" : customWidth || "576px"
          }}
          underlayClickExits={underlayClickExits}
          aria-modal={true}
          initialFocus={initialFocusSelector}
          focusDialog={!initialFocusSelector} // Focus the dialogue box itself if no selector for initial focus was provided
        >
          <Box padding="0" boxShadow="inset 0px -2px 0px 0px rgb(0, 80, 149)">
            <Box
              background={modalHeaderBg || WHITE}
              borderColor={SILVER_GREY}
              borderWidthOverride={`0 0 ${BORDER_THIN} 0`}
              padding={`${SPACING.XS} ${SPACING.MD}`}
            >
              <Cluster
                justify={showCloseIconButton ? "space-between" : "flex-start"}
                align={
                  showCloseIconButton && isMobile ? "flex-start" : "center"
                }
                nowrap
              >
                <Title
                  as="h2"
                  weight={FONT_WEIGHT_SEMIBOLD}
                  fontSize={isMobile ? FONT_SIZE.MD : FONT_SIZE.LG}
                >
                  {modalHeaderText}
                </Title>
                {showCloseIconButton && (
                  <CloseIconButton
                    hideModal={hideModal}
                    iconHeight={isMobile ? "20px" : "24px"}
                    iconWidth={isMobile ? "20px" : "24px"}
                  />
                )}
              </Cluster>
            </Box>
            <Box background={modalBodyBg || ATHENS_GREY} padding="0">
              <Box
                padding={SPACING.MD}
                borderWidthOverride={!noButtons && `0 0 ${BORDER_THIN} 0`}
                borderColor={!noButtons && SILVER_GREY}
                extraStyles={
                  maxHeight ? `max-height: ${maxHeight}; overflow: auto;` : ``
                }
              >
                {defaultWrapper ? (
                  <Paragraph variant={isMobile ? "pS" : "p"}>
                    {modalBodyText}
                  </Paragraph>
                ) : (
                  <Box padding={maxHeight ? `0 0 ${SPACING.XS} 0` : "0"}>
                    {modalBodyText}
                  </Box>
                )}
              </Box>
              {noButtons ? (
                <></>
              ) : (
                <ButtonLayoutWrapper isMobile={isMobile}>
                  {[
                    hasCancelButton && (
                      <CancelButton
                        buttonExtraStyles={buttonExtraStyles}
                        cancelAction={cancelAction}
                        cancelButtonText={cancelButtonText}
                        cancelButtonVariant={cancelButtonVariant}
                        hideModal={hideModal}
                        isMobile={isMobile}
                        key="cancel"
                      />
                    ),
                    hasContinueButton && (
                      <ContinueButton
                        buttonExtraStyles={buttonExtraStyles}
                        continueAction={continueAction}
                        continueButtonText={continueButtonText}
                        continueURL={continueURL}
                        continueButtonVariant={continueButtonVariant}
                        isContinueActionDisabled={isContinueActionDisabled}
                        isLoading={isLoading}
                        isMobile={isMobile}
                        key="continue"
                        useDangerButton={useDangerButton}
                      />
                    ),
                    hasCloseButton && (
                      <CloseButton
                        buttonExtraStyles={buttonExtraStyles}
                        closeButtonText={closeButtonText}
                        hideModal={hideModal}
                        isMobile={isMobile}
                        key="close"
                      />
                    )
                  ].filter(button => button)}
                </ButtonLayoutWrapper>
              )}
            </Box>
          </Box>
        </AriaModal>
      )}
      {children}
    </div>
  );
};

export default withWindowSize(Modal);
