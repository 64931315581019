import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import { fallbackValues } from "./ToggleSwitch.theme";
import { themeComponent } from "../../../util/themeUtils";
import Heading from "../heading";
import { Box, Center, Cover, Cluster } from "../layouts";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import { CHARADE_GREY } from "../../../constants/colors";
import { ENTER } from "../../../constants/keyboard";
import { noop } from "../../../util/general";

const HiddenToggleSwitchBox = styled.input`
  opacity: 0;
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  height: 24px;
  width: 50px;
  ${({ isMobile }) => (isMobile ? `transform: scale(0.75)` : ``)}
`;

const VisibleSwitchComponent = styled.label`
  width: 44px;
  height: 24px;
  border-radius: 12px;
  border: none;
  position: relative;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  display: inline-block;

  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled ? "none" : "0px 2px 5px 0px rgba(0, 0, 0, 0.5) !important"};
  }

  &:focus {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  }

  ${({ isMobile }) => (isMobile ? `transform: scale(0.75)` : ``)}
`;

const ToggleSwitchRingComponent = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
`;

const ToggleSwitch = ({
  isOn = false,
  onToggle = noop,
  disabled = false,
  name = "",
  label = null,
  labelLeft = false,
  themeValues,
  isMobile,
  dataQa
}) => {
  const ToggleSwitchRing = posed(ToggleSwitchRingComponent)({
    off: {
      backgroundColor: disabled
        ? themeValues.disabledBackground
        : themeValues.onBackground,
      left: "4px",
      top: "3px",
      bottom: "3px",
      right: "20px",
      transition: {
        ease: "backIn"
      }
    },
    on: {
      backgroundColor: themeValues.white,
      right: "8px",
      top: "3px",
      bottom: "3px",
      left: "22px",
      transition: {
        ease: "backIn"
      }
    }
  });

  const VisibleSwitch = posed(VisibleSwitchComponent)({
    focusable: true,
    hoverable: true,
    pressable: true,
    focus: {
      outline: "0px ridge rgba(170, 50, 220, 0)",
      boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.5)"
    },
    hover: {
      boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.5)"
    },
    off: {
      border: "1px solid",
      backgroundColor: disabled
        ? themeValues.disabledBackgroundLight
        : themeValues.white,
      borderColor: disabled
        ? themeValues.disabledBackground
        : themeValues.onBackground,
      transition: {
        ease: "easeOut"
      }
    },
    on: {
      border: "1px solid",
      backgroundColor: disabled
        ? themeValues.disabledBackground
        : themeValues.onBackground,
      borderColor: disabled
        ? themeValues.disabledBackground
        : themeValues.onBackground,
      transition: {
        ease: "easeIn"
      }
    }
  });

  const handleKeyDown = e => {
    if (e.keyCode === ENTER) {
      onToggle();
    }
  };

  return (
    <Box
      padding="0"
      extraStyles={
        labelLeft ? themeValues.leftLabelStyles : themeValues.rightLabelStyles
      }
    >
      <Center>
        <Cluster justify="stretch" align="center" overflow="visible">
          <Cover minHeight="100%" singleChild>
            <Box
              minWidth="100%"
              padding="0"
              extraStyles={`display: flex; align-items: center;`}
              dataQa={dataQa}
            >
              <HiddenToggleSwitchBox
                aria-label={name}
                checked={isOn}
                onChange={disabled ? noop : onToggle}
                disabled={disabled}
                id={`#toggle-${name}`}
                isMobile={isMobile}
              />
              <VisibleSwitch
                name={name}
                htmlFor={`#toggle-${name}`}
                onClick={disabled ? noop : onToggle}
                onKeyDown={disabled ? noop : handleKeyDown}
                pose={isOn ? "on" : "off"}
                tabIndex={disabled ? -1 : 0}
                disabled={disabled}
                isMobile={isMobile}
              >
                <ToggleSwitchRing />
              </VisibleSwitch>
            </Box>
          </Cover>
          {label && (
            <Heading
              variant={"h4"}
              weight={FONT_WEIGHT_SEMIBOLD}
              extraStyles={`margin: 0 0.5rem; position: relative; bottom: 1px; display: inline-block;`}
              color={CHARADE_GREY}
            >
              {label}
            </Heading>
          )}
        </Cluster>
      </Center>
    </Box>
  );
};

export default themeComponent(ToggleSwitch, "ToggleSwitch", fallbackValues);
