import React from "react";

const NoPaymentResultsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="366"
      height="209"
      viewBox="0 0 366 209"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-537 -466)">
          <g transform="translate(132 332)">
            <g transform="translate(405 134)">
              <path
                fill="#FFF"
                d="M73 25h224v144.75a2.25 2.25 0 01-2.25 2.25H75.25a2.25 2.25 0 01-2.25-2.25V25z"
              ></path>
              <path
                fill="#E4F4FD"
                d="M220 47.5c14.084 0 25.5 11.416 25.5 25.5 0 14.083-11.416 25.5-25.5 25.5S194.5 87.083 194.5 73c0-14.084 11.416-25.5 25.5-25.5zm-70 0c14.084 0 25.5 11.416 25.5 25.5 0 14.083-11.416 25.5-25.5 25.5-14.083 0-25.5-11.417-25.5-25.5 0-14.084 11.417-25.5 25.5-25.5zM220.001 68c-4.206 0-7.903 2.689-9.348 6.641a2.5 2.5 0 004.634 1.87l.062-.152.083-.214c.786-1.896 2.574-3.145 4.57-3.145 1.994 0 3.782 1.249 4.568 3.145l.083.214.061.152a2.5 2.5 0 004.635-1.87C227.903 70.69 224.208 68 220 68zM150 68c-4.206 0-7.902 2.685-9.346 6.632a2.506 2.506 0 001.497 3.215 2.512 2.512 0 003.158-1.342l.061-.153.083-.212c.781-1.884 2.562-3.126 4.547-3.126 1.986 0 3.767 1.242 4.55 3.127l.082.212.062.153a2.512 2.512 0 003.158 1.34 2.506 2.506 0 001.496-3.215c-1.445-3.946-5.141-6.631-9.348-6.631z"
              ></path>
              <path
                fill="#E8FFEF"
                d="M237 197c0 6.627-23.281 12-52 12-28.718 0-52-5.373-52-12s23.282-12 52-12c28.719 0 52 5.373 52 12"
              ></path>
              <path
                fill="#292A33"
                fillRule="nonzero"
                d="M294.625 0A6.376 6.376 0 01301 6.377V157h19a3 3 0 01.176 5.995L320 163h-19v6.623a6.376 6.376 0 01-6.375 6.377H75.374A6.375 6.375 0 0169 169.623V163H33a3 3 0 01-.176-5.995L33 157h36V6.377A6.375 6.375 0 0175.374 0h219.251zM297 25H73v144.623c0 1.259.976 2.288 2.211 2.372l.163.005h219.251a2.376 2.376 0 002.37-2.214l.005-.163V25zm39.953 132l26.112.082a2.952 2.952 0 012.935 2.969 2.953 2.953 0 01-2.78 2.945l-.173.004-26.112-.082a2.952 2.952 0 01-2.935-2.969 2.953 2.953 0 012.78-2.945l.173-.004zM17 157a3 3 0 01.176 5.995L17 163H3a3 3 0 01-.176-5.995L3 157h14zm179.832-42l.26.004c4.66.134 8.422 3.878 8.422 8.51a2.515 2.515 0 01-5.023.166l-.006-.166-.005-.183c-.096-1.763-1.573-3.2-3.446-3.297l-.202-.005h-23.664l-.196.005c-1.873.094-3.352 1.528-3.452 3.29l-.006.19-.005.166a2.515 2.515 0 01-5.023-.166c0-4.632 3.761-8.376 8.422-8.51l.26-.004h23.664zm23.169-72.5c16.845 0 30.499 13.654 30.499 30.5 0 16.845-13.655 30.5-30.5 30.5S189.5 89.845 189.5 73c0-.335.005-.668.016-1h-9.032l.012.499.004.5c0 16.846-13.655 30.501-30.5 30.501S119.5 89.845 119.5 73s13.655-30.5 30.5-30.5c10.22 0 19.266 5.026 24.8 12.742 3.73-1.495 7.226-2.242 10.491-2.242 3.304 0 6.593.765 9.87 2.296C200.69 47.55 209.755 42.5 220 42.5zm0 5C205.916 47.5 194.5 58.916 194.5 73c0 14.083 11.416 25.5 25.5 25.5s25.5-11.417 25.5-25.5c0-14.084-11.416-25.5-25.5-25.5zm-70 0C135.917 47.5 124.5 58.916 124.5 73c0 14.083 11.417 25.5 25.5 25.5 14.084 0 25.5-11.417 25.5-25.5 0-14.084-11.416-25.5-25.5-25.5zM185 59a3 3 0 100 6.002 3 3 0 000-6.002zM294.626 4H75.374a2.375 2.375 0 00-2.369 2.214L73 6.377V21h224V6.377a2.376 2.376 0 00-2.212-2.372L294.625 4zM81.5 10a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm8 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm8 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
              ></path>
              <path
                fill="#959CA8"
                fillRule="nonzero"
                d="M220.001 68c4.207 0 7.902 2.689 9.348 6.641a2.5 2.5 0 01-4.635 1.87l-.061-.152C223.916 74.343 222.07 73 220 73c-1.995 0-3.783 1.25-4.57 3.145l-.082.214a2.5 2.5 0 01-4.696-1.718C212.098 70.69 215.795 68 220 68zM150 68c4.207 0 7.903 2.685 9.348 6.631a2.506 2.506 0 01-1.496 3.216 2.512 2.512 0 01-3.158-1.34l-.062-.154c-.734-2.003-2.572-3.339-4.632-3.339-1.985 0-3.766 1.242-4.547 3.126l-.083.212a2.511 2.511 0 01-3.22 1.495 2.506 2.506 0 01-1.496-3.215C142.097 70.685 145.793 68 150 68z"
              ></path>
              <path
                fill="#E4E6EB"
                d="M294.75 4A2.25 2.25 0 01297 6.25V21H73V6.25A2.25 2.25 0 0175.25 4zM81.5 10a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm8 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm8 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
              ></path>
              <path
                fill="#317D4F"
                fillRule="nonzero"
                d="M182 176v20a4 4 0 01-3.8 3.995l-.2.005h-14a4 4 0 01-.2-7.995l.2-.005h10v-16h8zm14 0v16h10a4 4 0 013.995 3.8l.005.2a4 4 0 01-3.8 3.995l-.2.005h-14a4 4 0 01-3.995-3.8L188 196v-20h8zM69 128.5v9l-7.875 4.429 32.876 6.953c1.81-4.055 5.877-6.882 10.604-6.882a4 4 0 01.2 7.995l-.2.005a3.605 3.605 0 00-3.604 3.628l-.001-.023a3.606 3.606 0 003.414 3.601l.191.005h6.644a4 4 0 01.2 7.995l-.2.005h-6.644c-5.246 0-9.679-3.48-11.115-8.259l-44.708-9.457c-3.58-.757-4.322-5.493-1.242-7.336l.175-.1L69 128.5zm193.321-62.228a42.836 42.836 0 01.704 8.714l53.02 37.735a4 4 0 01.661 5.926l-.148.157L301 134.5V123l6.557-6.5-45.68-32.513a42.337 42.337 0 01-2.193 6.683 4 4 0 01-7.368-3.116 34.513 34.513 0 002.719-13.477c0-2.149-.195-4.27-.58-6.349a4 4 0 117.866-1.456z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NoPaymentResultsIcon;
