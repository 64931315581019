import React from "react";
import { connect } from "react-redux";
import MultiCart from "./MultiCart";

import {
  mapStateToProps,
  mapDispatchToProps
} from "../cart/ShoppingCart.state";

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(MultiCart));
